import {createAsyncThunk} from "@reduxjs/toolkit";
import {ProfileService} from "@api/services";
import {findError, notify} from "@utils/functions";

const fetchProfile = createAsyncThunk("profile/fetch", async (id, thunkAPI) => {
    try {
        return await ProfileService.fetchProfile(id);
    } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
    }
});

const updateProfile = createAsyncThunk("profile/update", async ({id, data}, thunkAPI) => {
    try {
        return await ProfileService.updateProfile(id, data);
    } catch (e) {
        notify(findError(e.response.data), "error");
        return thunkAPI.rejectWithValue(e.message);
    }
});
const updateNewUserStatus = createAsyncThunk("profile/updateStatus", async (thunkAPI) => {
    try {
        return await ProfileService.updateNewUserStatus();
    } catch (e) {
        notify(findError(e.response.data), "error");
        return thunkAPI.rejectWithValue(e.message);
    }
});
const submitProftest = createAsyncThunk("profile/submitProftest", async (data, thunkAPI) => {
    try {
        return await ProfileService.submitProftest(data);
    } catch (e) {
        notify(findError(e.response.data), "error");
        return thunkAPI.rejectWithValue(e.message);
    }
});
const fetchProftestResults = createAsyncThunk("profile/getProftestResults", async (thunkAPI) => {
    try {
        return await ProfileService.fetchProftestResults();
    } catch (e) {
        notify(findError(e.response.data), "error");
        return thunkAPI.rejectWithValue(e.message);
    }
});

export {
    fetchProfile, updateProfile, updateNewUserStatus, submitProftest, fetchProftestResults
};