import { useEffect, useState } from "react";
import { useTranslate } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import "./HeaderLanguageToggle.scss";

const HeaderLanguageToggle = () => {
  const { i18n } = useTranslate();
  const lastValue = localStorage.getItem("lang");
  const [lang, setLang] = useState(lastValue || "en");

  const languages = [
    { code: "en", label: "Eng" },
    { code: "ru", label: "Рус" },
    { code: "kz", label: "Қаз" },
  ];

  useEffect(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleLangChange = (newLang) => {
    setLang(newLang);
  };

  return (
    <List
      className={`switch-button ${lang}-active`}
      items={languages}
      renderItem={({ code, label }) => (
        <button
          key={code}
          className={`switch-button-option ${lang === code ? "active" : ""}`}
          onClick={() => handleLangChange(code)}
        >
          {lang === code && <Icon name="checkmark" className="checkmark" />}
          {label}
        </button>
      )}
    />
  );
};

export default HeaderLanguageToggle;
