import { List } from "@components/common";
import { CourseCard } from "@components/feature/Courses";
import { useAction, useTranslate } from "@helpers/hooks";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { DashboardCourseCard } from "../DashboardCourseCard";
import "./DashboardCourses.scss";

const DashboardCourses = () => {
  const { fetchMyCourses } = useAction();
  const { courses } = useSelector((state) => state.courses);
  const { t } = useTranslate();

  useEffect(() => {
    fetchMyCourses();
  }, []);

  if (!courses) {
    return;
  }
  return (
    <div className="dashboard__courses">
      <h2 className="dashboard__subheading">{t("DASHBOARD.MY_COURSES")}</h2>
      <List
        className={"dashboard__courses-list"}
        items={courses}
        renderItem={(item) => <DashboardCourseCard  key={item.id} course={item} />}
      />
    </div>
  );
};

export default DashboardCourses;
