import React, { useState } from "react";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { KASPI_PAYMENT_ROUTE, MOCK_EXAM_ONGOING } from "@utils/consts";
import { Tabs, PageTitle, List, NewModalLayout } from "@components/common";
import {
  Banner,
  BuyMockModal,
  InstructionModal,
  MockCard,
  MockFormModal,
  UpSale,
} from "@components/feature";

import "./MockSectionContent.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function MockSectionContent({ mocks }) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const history = useHistory();
  const { mocksFormCreate } = useAction();

  const [selectedTab, setSelectedTab] = useState("MOCK.NUET");
  const [selectedItem, setSelectedItem] = useState(null);
  const [buyModalItem, setBuyModalItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [formData, setFormData] = useState({ first_name: "", phone: "" });
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isSaleOpen, setIsSaleOpen] = useState(false);

  const tabs = {
    nuet: "MOCK.NUET",
    sat: "MOCK.SAT",
    ielts: "MOCK.IELTS",
  };

  const tabToExamTypeMap = {
    "MOCK.NUET": "nuet_mock",
    "MOCK.SAT": "sat_mock",
    "MOCK.IELTS": "ielts_mock",
  };

  const filteredExams = mocks.filter(
    (item) => item.type === tabToExamTypeMap[selectedTab]
  );

  const handleStart = (item) => {
    const examKey = `${item.type}_exam`;
    setSelectedItem(item);
    item[examKey]?.completed
      ? history.push(MOCK_EXAM_ONGOING.replace(":mockId", item.id))
      : setIsModalOpen(true);
  };

  const handleBuy = (item) => {
    setBuyModalItem(item);
    localStorage.setItem(
      "mock_purchase",
      JSON.stringify({ mock_id: item.id, access: item.access })
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleRedirectToKaspi = (item) => {
    history.push(
      KASPI_PAYMENT_ROUTE.replace(":type", "mock")
        .replace(":courseId", item.id)
        .replace(":packageId", 0)
        .replace(":courseName", item.title)
        .replace(":packageType", 0)
        .replace(":price", item.sum)
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    mocksFormCreate(formData);
    setIsFormModalOpen(false);
  };

  
  return (
    <div className="section__group-mock">
      <PageTitle title={"MOCK.TITLE"} />

      {isSaleOpen && (
        <UpSale isSaleOpen={isSaleOpen} setIsSaleOpen={setIsSaleOpen} />
      )}
      {isBannerOpen && (
        <Banner isBannerOpen={isBannerOpen} setIsBannerOpen={setIsBannerOpen} />
      )}

      <Tabs
        className={"catalog-tabs"}
        tabClassName={"catalog-tabs__tab "}
        tabs={[tabs.nuet, tabs.sat, tabs.ielts]}
        selected={selectedTab}
        setSelected={setSelectedTab}
      />

      <List
        items={filteredExams}
        className="mock__cards"
        renderItem={(item) => (
          <MockCard
            key={item.id}
            item={item}
            handleStart={handleStart}
            handleBuy={handleBuy}
          />
        )}
      />

      {isModalOpen && selectedItem && (
        <InstructionModal
          isMobile={isMobile}
          selectedItem={selectedItem}
          onClose={handleCloseModal}
        />
      )}

      {buyModalItem && (
        <BuyMockModal
          isMobile={isMobile}
          buyModalItem={buyModalItem}
          handleRedirectToKaspi={handleRedirectToKaspi}
          onClose={() => setBuyModalItem(null)}
        />
      )}

      {isFormModalOpen && (
        <MockFormModal
          isMobile={isMobile}
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleFormSubmit}
          onClose={() => setIsFormModalOpen(false)}
        />
      )}
    </div>
  );
}
