import { toast } from "react-toastify";
import './toastStyles.scss';

const DEFAULT_OPTIONS = {
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
};

export const notify = (message, type) => {
  const customClass = message == "Вы заработали 10 STP Coin!" ? 'Toastify__toast--custom' : '';
  toast(
    <div>
      {
        typeof message === "object"
          ? message.map((item, id) => (
            <p key={id}>{item}</p>
          ))
          : <p>{message}</p>
      }
    </div>,
    {
      ...DEFAULT_OPTIONS,
      type: type,
      className: customClass
    }
  );
};

export default notify;