export default function formatData(datetime, formatType) {
    const date = new Date(datetime);

    let day, month, year, hours, minutes, formattedDate;
  
    switch(formatType) {
        case 1:
            day = date.getDate();
            month = date.toLocaleString("default", { month: "short" });
            year = date.getFullYear();
            formattedDate = `${day} ${month} ${year}`;
            return formattedDate;
        case 2:
            day = String(date.getDate()).padStart(2, '0');
            month = String(date.getMonth() + 1).padStart(2, '0');
            year = date.getFullYear();
            formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        case 3:
            day = date.getUTCDate();
            month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
            formattedDate = `${day} ${month}`;
            return formattedDate;
        case 4:
            day = String(date.getDate()).padStart(2, '0');
            month = String(date.getMonth() + 1).padStart(2, '0');
            year = date.getFullYear();
            hours = String(date.getHours()).padStart(2, '0');
            minutes = String(date.getMinutes()).padStart(2, '0');
            formattedDate = `${day}.${month}.${year}, ${hours}:${minutes}`;
            return formattedDate;
        default:
            throw new Error(`Invalid format type: ${formatType}`);
    }
}
