import {useState} from "react";
import {useSelector} from "react-redux";
import {useTranslate} from "@helpers/hooks";
import {Input, List} from "@components/common";
import {EmailModal, PasswordModal} from "@components/feature";
import { PageTitle } from "@components/common/PageTitle";
import { useHistory } from 'react-router-dom';
import "./SettingsSecurity.scss";

export default function SettingsSecurity() {
    const history = useHistory();
    const {t} = useTranslate(),
        {profile} = useSelector(state => state.profile),
        [openEmail, setOpenEmail] = useState(false), [openPassword, setOpenPassword] = useState(false),
        securities = [
            {
                id: 1, type: "email", label: t("SETTINGS.EMAIL"), value: profile.email, handleClick: () => setOpenEmail(true)
            },
            {
                id: 2, type: "password", label: t("SETTINGS.PASSWORD"), value: "password", name: t("SETTINGS.CHANGE_PASSWORD"), handleClick: () => setOpenPassword(true)
            }
        ];

        const handleGoBack = () => {
            history.goBack();
          };
    return (
        <div className={"settings-security"}>
            <PageTitle title={'SETTINGS.SECURITY'} iconLeft={"arrow_title"} isGoBack/>
            <List
                className={"security__list"}
                items={securities}
                renderItem={(security) => (
                    <li key={security.id} className={"security__item"}>
                        <p className={"security__label"}>
                            {
                                security.label
                            }
                        </p>
                        <div className={"security__field"}>
                            <Input
                                className={"input--ghost security__input"}
                                type={security.type}
                                value={security.value}
                                readOnly
                            />
                            <p className={"security__name"} onClick={security.handleClick}>
                                {
                                    security?.name
                                }
                            </p>
                        </div>
                    </li>
                )}
            />
            <EmailModal
                open={openEmail}
                setOpen={setOpenEmail}
            />
            <PasswordModal
                open={openPassword}
                setOpen={setOpenPassword}
            />
        </div>
    );
};

