import React from "react";
import { NewModalLayout, Input, Icon, Button } from "@components/common";

const MockFormModal = ({ isMobile, formData, setFormData, onSubmit, onClose }) => (
  <NewModalLayout className={`pop-up ${isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`}`}>
    <div className="tariff__title-button">
      <h3>Fill the Form</h3>
      <Icon name="close-rounded" className="lms-badge-profile__close" handleClick={onClose} />
    </div>
    <form className="form" onSubmit={onSubmit}>
      <Input
        label="Name"
        placeholder="Full Name"
        name="first_name"
        value={formData.first_name}
        onChange={(e) => setFormData((prev) => ({ ...prev, first_name: e.target.value }))}
      />
      <Input
        label="Phone"
        placeholder="Full Number"
        name="phone"
        value={formData.phone}
        onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))}
      />
      <Button className="btn--primary btn--pill" type="submit" text="Send" />
    </form>
  </NewModalLayout>
);

export default MockFormModal;
