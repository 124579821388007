import React, { useMemo, memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@helpers/hooks";
import { CustomLink, Icon, List } from "@components/common";
import { HeaderCoins, HeaderLanguageToggle, HeaderProfile, LmsBadgeHeader, TourGuide } from "@components/feature";
import "./HeaderMenu.scss";

export default memo(function HeaderMenu() {
  const [isGuideActive, setIsGuideActive] = useState(false);
  const { t } = useTranslate();
  const { pathname } = useLocation();

  const navs = useMemo(() => {
    const items = [
      { id: 2, component: <Icon name='info_guide' handleClick={() => setIsGuideActive(true)} />, condition: !pathname.includes("section") },
      { id: 6, component: <HeaderCoins />, condition: true },
      { id: 3, component: <HeaderLanguageToggle />, condition: true },
      { id: 4, component: <LmsBadgeHeader />, condition: pathname.includes("courses") },
      { id: 5, component: <HeaderProfile />, condition: true },
    ];

    return items.filter(item => item.condition);
  }, [pathname, t]);

  return (
    <>
      {isGuideActive && <TourGuide />}
      <List
        className={"header__menu"}
        items={navs}
        renderItem={nav => (
          <li key={nav.id} className={"menu__item"}>
            {nav.component}
          </li>
        )}
      />
    </>
  );
});
