import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAction, useTranslate } from "@helpers/hooks";
import { useHistory } from "react-router-dom";
import { MOCK_EXAMS } from "@utils/consts";
import { convertQuestions } from "@utils/functions";
import { MockHeader, MockNavigation, QuizQuestionItem } from "@components/feature";
import { Button, NewModalLayout } from "@components/common";
import Confetti from "react-confetti";
import "./MockExamContent.scss";

export default function MockExamContent({mock}) {
  const {
    crit,
    math,
    stage,
    mathEndTime,
    critEndTime,
    verbalOne,
    verbalTwo,
    mathOne,
    mathTwo,
    verbalOneEndTime,
    verbalTwoEndTime,
    mathOneEndTime,
    mathTwoEndTime,
  } = useSelector((state) => state.mocks);
  const { t } = useTranslate();
  const {
    fetchCrit,
    fetchMath,
    submitMath,
    submitCrit,
    getProgress,
    getSatProgress,
    fetchVerbalOne,
    fetchVerbalTwo,
    fetchMathOne,
    fetchMathTwo,
    submitVerbalOne,
    submitVerbalTwo,
    submitMathOne,
    submitMathTwo,
  } = useAction();

  const isNuet = mock?.type == "nuet_mock";

  const [currentReviewSection, setCurrentReviewSection] = useState(
    `${isNuet ? "math" : "module1"}`
  );
  const history = useHistory();

  const initialState = {
    math: 0,
    crit: 0,
    module1: 0,
    module2: 0,
    module3: 0,
    module4: 0,
  };

  const useQuestionIndices = () => {
    const [indices, setIndices] = useState(initialState);

    const setCurrentIndex = (section, index) => {
      if (indices.hasOwnProperty(section)) {
        setIndices({ ...indices, [section]: index });
      }
    };

    const handleNavigation = (direction) => {
      const section = currentReviewSection;
      const currentIndex = indices[section];
      const newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

      if (newIndex >= 0 && newIndex < questionsNumber) {
        setCurrentIndex(section, newIndex);
      }
    };

    return { indices, setCurrentIndex, handleNavigation };
  };

  const { indices, setCurrentIndex, handleNavigation } = useQuestionIndices();
  const currentQuestionIndex = indices[currentReviewSection];

  const [isFinishConfirmationOpen, setIsFinishConfirmationOpen] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const celebrateCompletion = () => {
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
    }, 20000);
  };

  const [markedForLater, setMarkedForLater] = useState(() => {
    const saved = localStorage.getItem("markedForLater");
    return saved
      ? JSON.parse(saved)
      : {
          math: {},
          crit: {},
          module1: {},
          module2: {},
          module3: {},
          module4: {},
        };
  });

  const isMockCompleted = mock?.nuet_mock_exam?.completed || mock?.sat_mock_exam?.completed;
  const nuetMockId = mock && mock?.nuet_mock_exam?.id;
  const satMockId = mock && mock?.sat_mock_exam?.id;

  const sectionMappings = {
    math: math,
    crit: crit,
    module1: verbalOne,
    module2: verbalTwo,
    module3: mathOne,
    module4: mathTwo,
  };

  const endTimeMappings = {
    math: mathEndTime,
    crit: critEndTime,
    module1: verbalOneEndTime,
    module2: verbalTwoEndTime,
    module3: mathOneEndTime,
    module4: mathTwoEndTime,
  };

  let sectionData = sectionMappings[stage];

  let endTime = endTimeMappings[stage];
  const [userAnswers, setUserAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem("userAnswers");
    const initialAnswers = savedAnswers ? JSON.parse(savedAnswers) : {};
    return initialAnswers;
  });

  const questionsData = useMemo(() => {
    const sectionToDataMap = {
      math: math,
      crit: crit,
      module1: verbalOne,
      module2: verbalTwo,
      module3: mathOne,
      module4: mathTwo,
    };

    if (stage === "finished") {
      const sectionData = sectionToDataMap[currentReviewSection];
      return sectionData && sectionData.length > 0 && sectionData[0].questions
        ? convertQuestions(sectionData[0].questions)
        : [];
    } else if (sectionData && sectionData.length > 0) {
      return convertQuestions(sectionData[0]["questions"]);
    }
    return [];
  }, [
    math,
    crit,
    verbalOne,
    verbalTwo,
    mathOne,
    mathTwo,
    sectionData,
    stage,
    currentReviewSection,
  ]);

  const questionsNumber = questionsData.length;

  const isMarkedForLaterEmpty = !Object.values(markedForLater[stage] || {}).some(Boolean);
  const numberOfQuestionsMarked = Object.values(markedForLater[stage] || {}).filter(Boolean).length;

  const resetNavigationState = () => {
    setMarkedForLater({
      math: {},
      crit: {},
      module1: {},
      module2: {},
      module3: {},
      module4: {},
    });
  };

  const userResponses = Object.values(userAnswers).filter((answer) => answer !== null);

  useEffect(() => {
    const updatedUserAnswers = { ...userAnswers };
    let isUpdated = false;

    questionsData.forEach((question) => {
      if (updatedUserAnswers[question.id] === undefined) {
        updatedUserAnswers[question.id] = question.type === "multiple_choice" ? [] : null;
        isUpdated = true;
      }
    });

    if (isUpdated) {
      setUserAnswers(updatedUserAnswers);
    }
  }, [questionsData]);

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionId]: answer };
      localStorage.setItem("userAnswers", JSON.stringify(newAnswers));
      return newAnswers;
    });
  };

  let score;
  switch (isNuet) {
    case true:
      score = math && crit && [math[0]?.score, crit[0]?.score];
      break;
    case false:
      score =
        mathOne &&
        mathTwo &&
        verbalOne &&
        verbalTwo &&
        [mathOne[0]?.score + mathTwo[0]?.score, verbalOne[0]?.score + verbalTwo[0]?.score];
      break;
    default:
      score = "...";
  }

  const handleNextQuestion = () => handleNavigation("next");
  const handlePreviousQuestion = () => handleNavigation("prev");

  const handleRedirect = () => {
    history.push(MOCK_EXAMS);
  };

  const handleOpenPopup = () => {
    setIsFinishConfirmationOpen(true);
    celebrateCompletion();
  };

  const handleClosePopup = () => {
    setIsFinishConfirmationOpen(false);
    handleRedirect();
  };

  const handleSubmit = useCallback(async () => {
    const savedAnswers = JSON.parse(localStorage.getItem("userAnswers") || "{}");
    const result = Object.keys(savedAnswers).map((questionId) => ({
      answer: savedAnswers[questionId],
      user_id: 0,
      question_id: parseInt(questionId, 10),
    }));

    const stageConfig = {
      math: { submitFn: submitMath, id: mock?.nuet_mock_exam?.id },
      crit: { submitFn: submitCrit, id: mock?.nuet_mock_exam?.id, openPopup: true },
      module1: { submitFn: submitVerbalOne, id: mock?.sat_mock_exam?.id },
      module2: { submitFn: submitVerbalTwo, id: mock?.sat_mock_exam?.id },
      module3: { submitFn: submitMathOne, id: mock?.sat_mock_exam?.id },
      module4: { submitFn: submitMathTwo, id: mock?.sat_mock_exam?.id, openPopup: true },
    };

    try {
      const config = stageConfig[stage];
      if (config) {
        await config.submitFn({ id: config.id, data: JSON.stringify(result) });
        if (config.openPopup) handleOpenPopup();
      }

      isNuet ? getProgress(nuetMockId) : getSatProgress(satMockId);
      localStorage.removeItem("userAnswers");
      setUserAnswers({});
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
    resetNavigationState();
  }, [stage, mock, nuetMockId, satMockId]);

  useEffect(() => {
    setCurrentIndex(currentReviewSection, 0);
  }, [stage]);

  useEffect(() => {
    if (isNuet) {
      nuetMockId && getProgress(nuetMockId);
    } else {
      satMockId && getSatProgress(satMockId);
    }
  }, [nuetMockId, satMockId]);


  useEffect(() => {

    if (!mock) return;

    const mockId = isNuet ? nuetMockId : satMockId;

    const fetchFunctions = isNuet
      ? {
          math: fetchMath,
          crit: fetchCrit,
        }
      : {
          module1: fetchVerbalOne,
          module2: fetchVerbalTwo,
          module3: fetchMathOne,
          module4: fetchMathTwo,
        };

    if (stage === "finished") {
      Object.values(fetchFunctions).forEach((fetchFn) => fetchFn(mockId));
    } else if (fetchFunctions[stage]) {
      fetchFunctions[stage](mockId);
    }
  }, [stage, nuetMockId, satMockId, mock]);

  const setCurrentQuestionIndex = (index) => {
    setCurrentIndex(currentReviewSection, index);
  };

  return (
    <div className="ongoing__container">
      {stage && (
        <MockHeader
          mock={mock}
          isNuet={isNuet}
          handleSubmit={handleSubmit}
          endTime={endTime}
          stage={stage}
          isMockCompleted={isMockCompleted}
          handleRedirect={handleRedirect}
          sectionData={sectionData}
          score={score}
          userResponses={userResponses}
          questionsData={questionsData}
          currentReviewSection={currentReviewSection}
          isMarkedForLaterEmpty={isMarkedForLaterEmpty}
          numberOfQuestionsMarked={numberOfQuestionsMarked}
        />
      )}
      {confetti && (
        <Confetti
          width={windowWidth}
          height={windowHeight}
          numberOfPieces={1000}
          initialVelocityY={10}
          gravity={0.4}
        />
      )}
      {isFinishConfirmationOpen && (
        <NewModalLayout className="level-up">
          <h1 className="level-up__title">{t("MOCK.CONGRATS")}</h1>
          <Button
            text={t("LMS.BADGES.LETS_GO")}
            className="btn--secondary btn--pill level-up__btn"
            onClick={handleClosePopup}
          />
        </NewModalLayout>
      )}
      <div className="mock__main">
        {stage && (
          <MockNavigation
            userResponses = {userResponses}
            score={score}
            mock = {mock}
            totalQuestions={questionsNumber}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            currentQuestionIndex={currentQuestionIndex}
            stage={stage}
            currentReviewSection={currentReviewSection}
            setCurrentReviewSection={setCurrentReviewSection}
            handleNextQuestion={handleNextQuestion}
            handlePreviousQuestion={handlePreviousQuestion}
            questionsNumber={questionsNumber}
            userAnswers={userAnswers}
            questionsData={questionsData}
            markedForLater={markedForLater}
            setMarkedForLater={setMarkedForLater}
            isMockCompleted={isMockCompleted}
            isNuet={isNuet}
            handleRedirect={handleRedirect}
            isMarkedForLaterEmpty={isMarkedForLaterEmpty}
            handleSubmit={handleSubmit}
          />
        )}
        <form className="questions__form">
          {questionsNumber > 0 && (
            <QuizQuestionItem
              question={questionsData[currentQuestionIndex]}
              index={currentQuestionIndex + 1}
              userAnswers={userAnswers}
              handleAnswerChange={handleAnswerChange}
              isHorizontal={true}
              hasQuizAnswers={stage === "finished"}
              disabled={isMockCompleted}
            />
          )}
        </form>
      </div>
    </div>
  );
}
