import { Fragment, Suspense } from "react";
import { Article, Header, Sidebar } from "@components/common";
import { useLocation } from "react-router-dom";
import "./Layout.scss";
import { FixedBanner } from "@components/feature";

export default function Layout({ children, isBannerActive, onCloseBanner }) {
  const { pathname } = useLocation();

  return (
    <Fragment>
      {!pathname.includes("ongoing") && <Header />}
      {!pathname.includes("ongoing") && isBannerActive && (
        <FixedBanner onClose={onCloseBanner} />
      )}
      {!pathname.includes("ongoing") && <Sidebar />}
      <Article className={"private-layout__article"}>
        <Suspense fallback={<div>Loading...</div>}>
          {children}
        </Suspense>
      </Article>
    </Fragment>
  );
}
