import { VoiceRecord } from '@components/feature'
import React from 'react'
import "./SpeakingQuestion.scss"

export default function SpeakingQuestion({question, userAnswer, onAnswerChange}) {

  return (
    <div className="speaking__group">
        <VoiceRecord userAnswer={userAnswer} onAnswerChange={onAnswerChange} question={question}/>
    </div>
  )
}
