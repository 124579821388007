import { HeaderLanguageToggle } from "@components/feature";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../Icon";
import "./PageTitle.scss";

export default function PageTitle({
  title,
  iconLeft,
  iconRight,
  handleLeftClick,
  handleRightClick,
  isGoBack,
  isLogo,
  isLanguage
}) {
  const isMobile = useIsMobile();
  const history = useHistory()
  const {t} = useTranslate();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="page__title">
        {isMobile && <Icon name={isLogo ? 'logo_stp' : isGoBack ? 'arrow_title' : iconLeft} handleClick={isGoBack ? handleGoBack : handleLeftClick} className="page__icon-leading" />}
      {title && <h2 className="page__title-text">{t(title)}</h2>}
      {isMobile && isLanguage && (
        <HeaderLanguageToggle className={"page__icon-trailing"} />
      )}
      {isMobile && iconRight && (
        <Icon name={iconRight} handleClick={handleRightClick} className="page__icon-trailing" />
      )}
    </div>
  );
}
