import { DashboardProgram } from "./DashboardProgram";
import { DashboardSkeleton } from "./DashboardSkeleton";
import { DashboardList } from "./DashboardList";
import { DashboardChanceContent } from "./DashboardChanceContent";
import { DashboardFilters } from "./DashboardFilters";
import { DashboardGraphContent } from "./DashboardGraphContent";
import { DashboardChancesStats } from "./DashboardChancesStats";
import { DashboardStats } from "./DashboardStats";
import { DashboardAssessmentContent } from "./DashboardAssessmentContent";
import { DashboardIncreaseChances } from "./DashboardIncreaseChances";
import { DashboardBarsContent } from "./DashboardBarsContent";
import { DashboardAutocomplete } from "./DashboardAutocomplete";
import { DashboardBar } from "./DashboardBar";
import { DashboardMobile } from "./DashboardMobile";
import { DashboardProftest } from "./DashboardProftest";
import { DashboardCourses } from "./DashboardCourses";
import { DashboardCourseCard } from "./DashboardCourseCard";

export {
  DashboardProgram,
  DashboardList,
  DashboardSkeleton,
  DashboardChanceContent,
  DashboardFilters,
  DashboardGraphContent,
  DashboardChancesStats,
  DashboardStats,
  DashboardAssessmentContent,
  DashboardIncreaseChances,
  DashboardBarsContent,
  DashboardBar,
  DashboardAutocomplete,
  DashboardMobile,
  DashboardProftest,
  DashboardCourses,
  DashboardCourseCard,
};
