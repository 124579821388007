import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useAction, useTranslate} from "@helpers/hooks";
import {findCost, findDuration} from "@utils/functions";
import {Button, Divider, Icon, Image, List, Modal, TabPane, Tabs} from "@components/common";
import {ProgramOverview, ProgramRequirements, ProgramScholarships, ProgramRating, ProgramAboutSkeleton} from "@components/feature";
import "./ProgramModal.scss";

export default function ProgramModal({open, setOpen, onSelectUniversity}) {
    const {t} = useTranslate(),
        {program, isBtnLoading, isProgramLoading} = useSelector(state => state.program),
        {addFavorite, deleteFavorite} = useAction(),
        PROGRAM_MODAL_CHARACTERS = useMemo(() => [
            {id: 1, name: "Degree", value: program.study_level},
            {id: 2, name: "Learning format", value: program.study_mode},
            {id: 3, name: "Duration", value: findDuration(program.duration_field_test)},
            {id: 4, name: "Cost", value: findCost(program.fees_field_test)},
            {id: 5, name: "Scholarship", value: program.scholarships},
            {id: 6, name: "Deadline", value: program.start_date},
            {id: 7, name: "Language", value: program.language},
            {id: 8, name: "Ranking", value: program.rating__QS}
        ], [program]),
        tabs = {
            overview: "Overview",
            requirements: "Requirements",
            scholarships: `Scholarships(${program?.about_scholarship?.length > 0 ? program?.about_scholarship?.length : 0})`,
            rating: "Ranking"
        },
        [selectedTab, setSelectedTab] = useState(tabs.overview);

    const onAddProgramToFavorite = () => addFavorite(program.id);
    const onDeleteProgramFromFavorite = () => deleteFavorite(program.id);
    return (
        <Modal
            className={"program-modal"}
            width={1112}
            open={open}
            setOpen={setOpen}
            isClose={true}
            isStart={true}
        >
            {
                !isProgramLoading
                    ? <div className={"modal__wrapper"}>
                        <h6 className={"modal__title"}>
                            {program.name}
                        </h6>
                        <div className={"modal__about"}>
                            <div className={"about__picture"}>
                                <Image
                                    className={"about__img"}
                                    src={program.university?.logo}
                                    alt={program.name}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "/assets/img/program.png";
                                    }}
                                />
                            </div>
                            <div className={"about__info"}>
                                <p className={"about__desc"}>
                                    {program.about_program}
                                </p>
                                <div className={"about__location"}>
                                    <div className={"location__content"} onClick={() => onSelectUniversity(program.university.id)}>
                                        <Icon name={"university-list"}/>
                                        <p>{program.university?.title}</p>
                                    </div>
                                    <div className={"location__content"}>
                                        <Icon name={"location"}/>
                                        {
                                            program.university?.city.length > 0 &&
                                            program.university.city.map(({id, name}, universityId) =>
                                                <p key={id}>
                                                    {name}{universityId !== program.university.city.length - 1 && ','}
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    program.is_shortlisted
                                        ? <Button
                                            className={"about__btn btn--danger btn--pill"}
                                            text={t("BUTTON.DELETE_FROM_WISHLIST")}
                                            loading={isBtnLoading}
                                            onClick={onDeleteProgramFromFavorite}
                                        />
                                        : <Button
                                            className={"about__btn btn--primary btn--pill"}
                                            text={t("BUTTON.ADD_TO_WISHLIST")}
                                            loading={isBtnLoading}
                                            onClick={onAddProgramToFavorite}
                                        />
                                }
                            </div>
                        </div>
                        <List
                            className={"modal__character"}
                            items={PROGRAM_MODAL_CHARACTERS}
                            renderItem={(character) => (
                                character.value && <li key={character.id} className={"character__item"}>
                                    <p className={"item__name"}>
                                        {(character.name)}
                                    </p>
                                    <h5 className={"item__value"}>
                                        {character.value ?? "No data"}
                                    </h5>
                                </li>
                            )}
                        />
                        <Divider
                            className={"modal__divider"}
                            height={1}
                            color={"#F3F2F2"}
                        />
                        <Tabs
                            className={"modal__tabs tabs--default"}
                            tabClassName={"modal__tab"}
                            tabs={[tabs.overview, tabs.requirements, tabs.scholarships, tabs.rating]}
                            selected={selectedTab}
                            setSelected={setSelectedTab}
                        />
                        <TabPane
                            className={"modal__pane"}
                            isSelected={selectedTab === tabs.overview}
                        >
                            <ProgramOverview program={program}/>
                        </TabPane>
                        <TabPane
                            className={"modal__pane"}
                            isSelected={selectedTab === tabs.requirements}
                        >
                            <ProgramRequirements program={program}/>
                        </TabPane>
                        {
                            program.about_scholarship?.length > 0 && <TabPane
                                className={"modal__pane"}
                                isSelected={selectedTab === tabs.scholarships}
                            >
                                <ProgramScholarships aboutScholarship={program.about_scholarship}/>
                            </TabPane>
                        }
                        <TabPane
                            className={"modal__pane"}
                            isSelected={selectedTab === tabs.rating}
                        >
                            <ProgramRating program={program}/>
                        </TabPane>
                    </div>
                    : <ProgramAboutSkeleton/>
            }
        </Modal>
    );
};