// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard__courses {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.dashboard__courses-list {
  display: flex;
  gap: 20px;
  overflow-y: scroll;
  margin: 0 -32px;
  padding: 0 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Dashboard/DashboardCourses/DashboardCourses.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AACJ","sourcesContent":[".dashboard__courses {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  &-list {\n    display: flex;\n    gap: 20px;\n    overflow-y: scroll;\n    margin: 0 -32px;\n    padding: 0 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
