import React from "react";
import "./FixedBanner.scss";
import { useSelector } from "react-redux";
import { BtnLoader, Button } from "@components/common";
import { CountdownTimer } from "../CountdownTimer";
import { trackAmplitude } from "@utils/functions";
import { useIsMobile } from "@helpers/hooks";

export default function FixedBanner({ onClose }) {
  const { announces, isLoading } = useSelector((state) => state.directory);
  const announce = announces?.[0];
  const isMobile = useIsMobile();

  const handleClick = () => {
    trackAmplitude("announce_more_click", {
      description: "Пользователь кликнул анонс",
      announce_name: announce?.title,
    });
    window.location.href = announce.button_link;
  };

  if (isLoading) {
    return <BtnLoader />;
  }

  return (
    <div className="fixed__banner" onClick={handleClick}>
      <img
        className="fixed__banner-image-one"
        src={"/assets/img/announce_speaker.png"}
      />
      <h4 className="fixed__banner-title">{announce?.title}</h4>
      <div className="fixed__banner-buttons">
        {new Date() < new Date(announce.end_date) && !isMobile && (
          <CountdownTimer
            endDate={announce?.end_date}
            className="announce__timer"
          />
        )}
        <Button
          onClick={handleClick}
          className={"btn--announce"}
          text={announce?.button_link_text}
        />
      </div>
      <img
        className="fixed__banner-image-three"
        src={"/assets/img/announce_sales.png"}
      />
    </div>
  );
}
