import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Icon, Image, List } from "@components/common";
import { AudioPlayer } from "@components/feature";
import { QuizQuestionItem } from "@components/feature/Lesson";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import {
  blobToBase64,
  capitalizeFirstLetter,
  countWords,
} from "@utils/functions";
import { useTranslate } from "@helpers/hooks";

import "./MockIeltsSection.scss";

export default function MockIeltsSection({
  sectionData,
  userAnswers,
  setUserAnswers,
  questionsData,
  currentStageIndex,
  setCurrentStageIndex,
  currentReviewSection,
  setCurrentReviewSection,
  sectionMappings,
}) {
  const { stage } = useSelector((state) => state.mocks);
  const [wordcount, setWordcount] = useState(0);
  const { t } = useTranslate();

  const handleTextChange = (event) => {
    const text = event.target.value;
    const wordCount = countWords(text);
    setWordcount(wordCount);
  };

  const handleAnswerChange = useCallback(
    async (questionId, answer) => {
      const processAnswer = async (answer) => {
        if (stage === "speaking" && answer instanceof Blob) {
          return await blobToBase64(answer);
        } else if (stage === "writing" && typeof answer === "string") {
          const wordCount = countWords(answer);
          setWordcount(wordCount);
        }
        return answer;
      };

      const newAnswer = await processAnswer(answer);

      setUserAnswers((prevAnswers) => {
        const newAnswers = { ...prevAnswers, [questionId]: newAnswer };
        localStorage.setItem("userAnswers", JSON.stringify(newAnswers));
        return newAnswers;
      });
    },
    [stage, setUserAnswers]
  );

  const handleNextStage = () => {
    if (currentStageIndex < sectionData.length - 1) {
      setCurrentStageIndex(currentStageIndex + 1);
    }
  };

  const handlePrevStage = () => {
    if (currentStageIndex > 0) {
      setCurrentStageIndex(currentStageIndex - 1);
    }
  };

  const renderTitle = () => {
    return capitalizeFirstLetter(
      stage === "finished" ? currentReviewSection : stage
    );
  };

  const TABS = [
    {
      key: "listening",
      label: sectionMappings["listening"]
        ? `Listening (${sectionMappings["listening"][0].score})`
        : "Listening",
    },
    {
      key: "reading",
      label: sectionMappings["reading"]
        ? `Reading (${sectionMappings["reading"][0].score})`
        : "Reading",
    },
    {
      key: "writing",
      label: sectionMappings["writing"]?.[0].score
        ? `Writing (${sectionMappings["reading"][0].score})`
        : "Writing",
    },
    {
      key: "speaking",
      label: sectionMappings["speaking"]?.[0].score
        ? `Speaking (${sectionMappings["speaking"][0].score})`
        : "Speaking",
    },
  ];

  const handleReviewSection = (tab) => {
    setCurrentStageIndex(0);
    setCurrentReviewSection(tab);
  };

  const renderAudioPlayer = () => (
    <div className="section__audio">
      <AudioPlayer
        src={sectionData[currentStageIndex]?.audio}
        isMock={true}
        isSettings={false}
      />
    </div>
  );

  const renderTextContent = (text) => (
    <div className="section__text">
      <div className="section__questions-container">
        <ReactMarkdown className="section__text-text">{text}</ReactMarkdown>
      </div>
    </div>
  );

  const renderQuestionList = (items) => (
    <List
      className={"section__questions-list"}
      items={items}
      renderItem={(item) => (
        <QuizQuestionItem
          key={item.id}
          question={item}
          userAnswers={userAnswers}
          handleAnswerChange={handleAnswerChange}
          index={item.position}
        />
      )}
    />
  );

  const renderWritingQuestion = (text, images) => (
    <div className="writing__question">
      <ReactMarkdown className="section__text-text">{text}</ReactMarkdown>
      {images && <Image className={"quiz__image"} src={images} />}
    </div>
  );


  return (
    <div className="section">
      <nav className="section__nav">
        <div className="section__nav-stages">
          <h3>
            {renderTitle()}. Part {currentStageIndex + 1}
          </h3>
        </div>

        <div className="section__nav-controls">
          {stage === "finished" && (
            <ul className={"section__tabs"}>
              {TABS.map((tab) => {
                const isSelected =
                  tab.key.toLowerCase() === currentReviewSection.toLowerCase();
                return (
                  <li
                    key={tab.key}
                    className={classNames("section__tab", {
                      active: isSelected,
                    })}
                    onClick={() => handleReviewSection(tab.key)}
                  >
                    <p className={"section__tab-text"}>{tab.label}</p>
                  </li>
                );
              })}
            </ul>
          )}

          <div className="section__nav-arrow" onClick={handlePrevStage}>
            <Icon name={"arrow-left-nav"} />
          </div>
          <div className="section__nav-arrow" onClick={handleNextStage}>
            <Icon name={"arrow-right-nav"} />
          </div>
        </div>
      </nav>

      <div className="section__content">
        {(stage === "listening" ||
          (stage === "finished" && currentReviewSection === "listening")) && (
          <div className="section__content-narrow">
            {renderAudioPlayer()}
            {renderQuestionList(questionsData)}
          </div>
        )}

        {(stage === "reading" ||
          (stage === "finished" && currentReviewSection === "reading")) &&
          renderTextContent(sectionData[currentStageIndex]?.reading_text)}

        {(stage == "reading" ||
          (stage === "finished" && currentReviewSection === "reading")) && (
          <div className="section__questions">
            <div className="section__questions-container">
              {renderQuestionList(questionsData)}
            </div>
          </div>
        )}

        {(stage == "writing" ||
          (stage === "finished" && currentReviewSection === "writing")) && (
          <div className="section__questions">
            <div className="section__questions-container">
              {renderWritingQuestion(
                questionsData[0].question_text,
                questionsData[0]?.question_practice.images_store[0]?.image
              )}
            </div>
          </div>
        )}

        {(stage === "writing" ||
          (stage === "finished" && currentReviewSection === "writing")) && (
          <div className="writing__text">
            {questionsData && renderQuestionList([questionsData[0]])}
            <div className="writing__text-wordcount"></div>
          </div>
        )}

        {(stage === "speaking" ||
          (stage === "finished" && currentReviewSection === "speaking")) && (
          <div className="section__content-narrow">
            {questionsData && renderQuestionList(questionsData)}
          </div>
        )}
      </div>
    </div>
  );
}
