import { LmsService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchCourses = createAsyncThunk("courses/fetchAll", async (thunkAPI) => {
  try {
    const res = await LmsService.fetchCourses();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchCourse = createAsyncThunk("courses/fetchOne", async (id, thunkAPI) => {
  try {
    const res = await LmsService.fetchCourse(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchMyCourses = createAsyncThunk("courses/fetchAll/my", async (thunkAPI) => {
  try {
    const res = await LmsService.fetchMyCourses();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchLesson = createAsyncThunk("lessons/fetchOne", async (id, thunkAPI) => {
  try {
    const res = await LmsService.fetchLesson(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const changeLessonStatus = createAsyncThunk("lessons/completed", async (id, thunkAPI) => {
  try {
    return await LmsService.changeLessonStatus(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const submitQuizResults = createAsyncThunk("quizzes/save", async ({id, data}, thunkAPI) => {
  try {
    return await LmsService.submitQuizResults(id, data)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const deleteQuizResults = createAsyncThunk("quizzes/deleteResults", async (id, thunkAPI) => {
  try {
    return await LmsService.deleteQuizResults(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const createNote = createAsyncThunk("notes/create", async (data, thunkAPI) => {
  try {
    return await LmsService.createNote(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const getLessonNotes = createAsyncThunk("lesson/getNotes", async (id, thunkAPI) => {
  try {
    return await LmsService.getLessonNotes(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const editNote = createAsyncThunk("notes/edit", async ({ id, data }, thunkAPI) => {
  try {
    return await LmsService.editNote(id, data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const deleteNote = createAsyncThunk("notes/delete", async (id, thunkAPI) => {
  try {
    await LmsService.deleteNote(id);
    return id;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchWorkshop = createAsyncThunk("workshop/fetchOne", async (id, thunkAPI) => {
  try {
    const res = await LmsService.fetchWorkshop(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchWorkshops = createAsyncThunk("workshops/fetchAll", async (id, thunkAPI) => {
  try {
    const res = await LmsService.fetchWorkshops(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const postAttendanceQuizCode = createAsyncThunk(
  "workshops/postAttendanceQuizCode", async ({workshopId, code}, thunkAPI) => {
    try {
      const res = await LmsService.postAttendanceQuizCode(workshopId, code);
      return {res, workshopId};
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchGrades = createAsyncThunk("grades/fetchAll", async (courseId, thunkAPI) => {
  try {
    const res = await LmsService.fetchGrades(courseId);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchMockGrades = createAsyncThunk("grades/fetchMockAll", async (courseId, thunkAPI) => {
  try {
    const res = await LmsService.fetchMockGrades(courseId);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchNotes = createAsyncThunk("notes/fetchAll", async (_, thunkAPI) => {
  try {
    const res = await LmsService.fetchNotes();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchCourseNotes = createAsyncThunk("notes/fetchCourseNotes", async (id, thunkAPI) => {
  try {
    const res = await LmsService.fetchCourseNotes(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});


const fetchBadge = createAsyncThunk("badges/fetchOne", async (_, thunkAPI) => {
  try {
    const res = await LmsService.fetchBadge();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});


const fetchBadgeCategories = createAsyncThunk("badges/fetchCategories", async (_, thunkAPI) => {
  try {
    const res = await LmsService.fetchBadgeCategories();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchBadgeRating = createAsyncThunk("badges/fetchRating", async (_, thunkAPI) => {
  try {
    const res = await LmsService.fetchBadgeRating();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

// const fetchMockListening = createAsyncThunk("mocks/fetchMockExam/listening", async ({course_id, mock_id}, thunkAPI) => {
//   try {
//     const res = await LmsService.fetchMockListening(course_id, mock_id);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });
const createBadgeCategory = createAsyncThunk("badges/create", async (data, thunkAPI) => {
  try {
    const res = await LmsService.createBadgeCategory(data);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

// const fetchMockReading = createAsyncThunk("mocks/fetchMockExam/reading", async ({course_id, mock_id}, thunkAPI) => {
//   try {
//     const res = await LmsService.fetchMockReading(course_id, mock_id);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });
// const fetchMockSpeaking = createAsyncThunk("mocks/fetchMockExam/speaking", async ({course_id, mock_id}, thunkAPI) => {
//   try {
//     const res = await LmsService.fetchMockSpeaking(course_id, mock_id);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });

// const fetchMockWriting = createAsyncThunk("mocks/fetchMockExam/writing", async ({course_id, mock_id}, thunkAPI) => {
//   try {
//     const res = await LmsService.fetchMockWriting(course_id, mock_id);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });

// const saveMockListening = createAsyncThunk("mocks/fetchMockExam/listening/save/", async ({course_id, mock_id, data}, thunkAPI) => {
//   try {
//     const res = await LmsService.saveMockListening(course_id, mock_id, data);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });

// const saveMockReading = createAsyncThunk("mocks/fetchMockExam/reading/save/", async ({course_id, mock_id, data}, thunkAPI) => {
//   try {
//     const res = await LmsService.saveMockReading(course_id, mock_id, data);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });

// const saveMockSpeaking = createAsyncThunk("mocks/fetchMockExam/speaking/save/", async ({course_id, mock_id, data}, thunkAPI) => {
//   try {
//     const res = await LmsService.saveMockSpeaking(course_id, mock_id, data);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });

// const saveMockWriting = createAsyncThunk("mocks/fetchMockExam/writing/save/", async ({course_id, mock_id, data}, thunkAPI) => {
//   try {
//     const res = await LmsService.saveMockWriting(course_id, mock_id, data);
//     return res;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e.response.data);
//   }
// });

const getPaymentLinks = createAsyncThunk("courses/getPaymentLinks", async ({courseId}, thunkAPI) => {
  try {
    const res = await LmsService.getPaymentLinks(courseId);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});


const updateAliveStatus = createAsyncThunk("marathon/updateAliveStatus", async ({number}, thunkAPI) => {
  try {
    const res = await LmsService.updateAliveStatus(number);
    } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});


const fetchSquidRanking = createAsyncThunk("squid/fetchRanking", async (_, thunkAPI) => {
  try {
    const res = await LmsService.fetchSquidRanking();

    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});


const setToZero = createAsyncThunk("marathon/setToZero", async (thunkAPI) => {
  try {
    const res = await LmsService.setToZero();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const kaspiCreate = createAsyncThunk("courses/kaspiCreate", async ({course_id, package_type}, thunkAPI) => {
  try {
    const res = await LmsService.kaspiCreate(course_id, package_type);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const kaspiMockCreate = createAsyncThunk("courses/kaspiMockCreate", async (mock_id, thunkAPI) => {
  try {
    const res = await LmsService.kaspiMockCreate(mock_id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const mocksFormCreate = createAsyncThunk("amocrm/create", async (form_id, thunkAPI) => {
  try {
    const res = await LmsService.mocksFormCreate(form_id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export {
  changeLessonStatus,
  createNote,
  deleteNote,
  deleteQuizResults,
  editNote,
  fetchCourse,
  fetchCourses,
  fetchLesson,
  fetchWorkshop,
  fetchWorkshops,
  getLessonNotes,
  postAttendanceQuizCode,
  submitQuizResults,
  fetchGrades,
  fetchMockGrades,
  fetchNotes,
  fetchCourseNotes,
  // fetchMockListening,
  // fetchMockReading,
  // fetchMockSpeaking,
  // fetchMockWriting,
  fetchSquidRanking,
  // saveMockListening,
  // saveMockReading,
  // saveMockSpeaking,
  // saveMockWriting,
  fetchBadge,
  fetchBadgeCategories,
  fetchBadgeRating,
  createBadgeCategory,
  getPaymentLinks,
  updateAliveStatus,
  setToZero,
  kaspiCreate,
  kaspiMockCreate,
  mocksFormCreate,
  fetchMyCourses
};
