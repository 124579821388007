import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { WithAuth } from "@helpers/hoc";
import { FallbackError, YandexMetrikaContainer } from "@components/common";
import { AppRouter } from "@components/feature";
import { useVisibilityTracking } from "@helpers/hooks";
import { useBanner } from "@helpers/hooks";
import "./App.scss";

export default function App() {
  const { pathname } = useLocation();
  useVisibilityTracking();
  const { isBannerActive, handleCloseBanner, currentAnnouncement } =
    useBanner();

  return (
    <WithAuth>
      <YandexMetrikaContainer />
      <ErrorBoundary FallbackComponent={FallbackError}>
        <main
          className={`app ${
            pathname.includes("practice") || pathname.includes("ongoing")
              ? "app-practice"
              : ""
          } ${isBannerActive ? "banner-active" : ""}`}
        >
          <AppRouter
            isBannerActive={isBannerActive}
            onCloseBanner={() => handleCloseBanner(currentAnnouncement?.id)}
          />
        </main>
      </ErrorBoundary>
    </WithAuth>
  );
}
