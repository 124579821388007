import React, { useState } from "react";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { KASPI_PAYMENT_ROUTE, MOCK_EXAM_ONGOING } from "@utils/consts";
import {
  ButtonLink,
  Input,
  List,
  Icon,
  Button,
  Tabs,
  NewModalLayout,
  PageTitle,
  Image,
} from "@components/common";
import "./MockSectionContent.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Banner, UpSale } from "@components/feature";

export default function MockSectionContent({ mocks }) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const { mocksFormCreate } = useAction();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [formData, setFormData] = useState({ first_name: "", phone: "" });

  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isSaleOpen, setIsSaleOpen] = useState(false);
  const tabs = {
    nuet: "MOCK.NUET",
    sat: "MOCK.SAT",
    ielts: "MOCK.IELTS",
  };

  const tabToExamTypeMap = {
    "MOCK.NUET": "nuet_mock",
    "MOCK.SAT": "sat_mock",
    "MOCK.IELTS": "ielts_mock",
  };

  const [selectedTab, setSelectedTab] = useState(tabs.nuet);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buyModalItem, setBuyModalItem] = useState(null);

  const discountedPrice = 10000;

  const filteredExams = mocks.filter(
    (item) => item.type === tabToExamTypeMap[selectedTab]
  );

  const handleStart = (item) => {
    const examKey = determineExamKey(item);
    setSelectedItem(item);
    item[examKey]?.completed
      ? history.push(MOCK_EXAM_ONGOING.replace(":mockId", item.id))
      : setIsModalOpen(true);
  };

  const handleBuy = (item) => {
    setBuyModalItem(item);
    localStorage.setItem(
      "mock_purchase",
      JSON.stringify({ mock_id: item.id, access: item.access })
    );
  };

  const handleCloseBuyModal = () => {
    setBuyModalItem(false);
  };

  const handleLoanButtonClick = (item) => {
    setIsFormModalOpen(true);
    setSelectedItem(item);
  };

  const handleRedirectToKaspi = (item) => {
    history.push(
      KASPI_PAYMENT_ROUTE.replace(":type", "mock")
        .replace(":courseId", item.id)
        .replace(":packageId", 0)
        .replace(":courseName", item.title)
        .replace(":packageType", 0)
        .replace(":price", item.sum)
    );
  };

  const calculateDiscountedPrice = (sum) => {
    return (sum / discountedPrice - 1) * 100;
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    mocksFormCreate(formData);
    setIsFormModalOpen(false);
  };

  const handleInstructions = (type) => {
    const instructionsMap = {
      nuet_mock: "MOCK.NUET_INSTRUCTION",
      ielts_mock: "MOCK.IELTS_INSTRUCTION",
    };

    return instructionsMap[type] || null;
  };

  function determineExamKey(item) {
    return `${item.type}_exam`;
  }

  return (
    <div className="section__group-mock">
      <PageTitle title={"MOCK.TITLE"} />
      {isSaleOpen && (
        <UpSale isSaleOpen={isSaleOpen} setIsSaleOpen={setIsSaleOpen} />
      )}
      {isBannerOpen && (
        <Banner isBannerOpen={isBannerOpen} setIsBannerOpen={setIsBannerOpen} />
      )}
      <Tabs
        className={"catalog-tabs"}
        tabClassName={"catalog-tabs__tab "}
        tabs={[tabs.nuet, tabs.sat, tabs.ielts]}
        selected={selectedTab}
        setSelected={(tab) => {
          setSelectedTab(tab);
        }}
      />
      <List
        items={filteredExams}
        className="mock__cards"
        renderItem={(item) => {
          const examKey = determineExamKey(item);
          const originalPrice = item.sum;
          const discount = calculateDiscountedPrice(item.sum);
          const maxScore =
            item.type === "ielts_mock"
              ? "9.0"
              : item.type === "nuet_mock"
              ? 240
              : 1600;
          return (
            <li key={item.id} className={"mock__card"}>
              {!item[examKey]?.completed ? (
                <div className="mock__card_text">
                  <div className="mock__card_text_nums">
                    <h4 className="mock__card-title">{item.title}</h4>
                    <div className="mock__card_text_nums-nums">
                      <p className="mock__card_text_nums-nums-original">
                        {discountedPrice}
                      </p>
                      <p className="mock__card_text_nums-nums-discount">
                        {originalPrice}
                      </p>
                    </div>
                  </div>
                  <div className="mock__card_text_circle">
                    <div className="mock__card_circle">{discount}%</div>
                  </div>
                </div>
              ) : (
                <div className="mock__card_text">
                  <div className="mock__card_text_nums">
                    <h4 className="mock__card-title">{item.title}</h4>
                    <div className="mock__card_text_nums-nums">
                      <p className="mock__card_text_nums-nums-score">
                        {t("MOCK.YOUR_SCORE")} {Math.round(item[examKey].score)}
                        /{maxScore}
                      </p>
                    </div>
                  </div>
                  <div className="mock__card_text_circle"></div>
                </div>
              )}
              <div className="mock__card_text__buttons">
                <Button
                  className={
                    item[examKey]?.completed
                      ? "mock__card_text__buttons_widy card__button"
                      : "card__button card__button-primary card__buttons-width"
                  }
                  text={
                    item[examKey]?.completed
                      ? t("MOCK.FEEDBACK")
                      : item.access
                      ? t("MOCK.START_EXAM")
                      : t("BUTTON.BUY")
                  }
                  onClick={
                    item.access
                      ? () => handleStart(item)
                      : () => handleBuy(item)
                  }
                />
              </div>

              {isModalOpen && selectedItem && (
                <NewModalLayout
                  className={`pop-up ${
                    isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
                  }`}
                >
                  <div className="tariff__title-button">
                    <h3 className="">{selectedItem.title}</h3>
                    <Icon
                      name="close-rounded"
                      className="lms-badge-profile__close"
                      handleClick={handleClose}
                    />
                  </div>

                  <p>{t(handleInstructions(selectedItem.type))}</p>
                  <div className="mock__warning">
                    <Icon name={`lms-warning`} />
                    <p>{t("MOCK.WARNING")}</p>
                  </div>

                  <div className="popup__buttons">
                    <Button
                      className="button-secondary popup__button"
                      text={t("MOCK.CANCEL")}
                      onClick={() => handleClose()}
                    />
                    <ButtonLink
                      className="button-primary popup__button"
                      text={t("MOCK.START_EXAM")}
                      link={MOCK_EXAM_ONGOING.replace(
                        ":mockId",
                        selectedItem.id
                      )}
                    />
                  </div>
                </NewModalLayout>
              )}
              {buyModalItem && (
  <NewModalLayout
    className={`pop-up mock__card-popup-box ${
      isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
    }`}
  >
    <div className="tariff__title-button">
      <h3 className="">{t("KASPI.MOCK_TITLE")}</h3>
      <Icon
        name="close-rounded"
        className="lms-badge-profile__close"
        handleClick={() => setBuyModalItem(null)}
      />
    </div>
    <div className="mock__card-popup">
      <div className="mock__card-popup-top">
        <div className="mock__card-popup-info">
          <h3>{buyModalItem.title}</h3>
          <div className="mock__card-popup-price">
            <del>{discountedPrice} ₸</del>
            <p>{buyModalItem.sum} ₸</p>
          </div>
        </div>
        <div className="discount-circle">
          {calculateDiscountedPrice(buyModalItem.sum)}%
        </div>
      </div>
      <div className="mock__card-popup-mid">
        <Icon name="help-info" />
        <div className="mock__card-popup-mid-info">
          <h4 className="">{t("KASPI.GUIDE_TITLE")}</h4>
          <ol>
            <li>{t("KASPI.GUIDE_ONE")}</li>
            <li>{t("KASPI.GUIDE_TWO")}</li>
            <li>{t("KASPI.GUIDE_THREE")}</li>
          </ol>
        </div>
      </div>
      <div className="mock__card-popup-bottom">
        <button
          className="btn btn--red btn--pill"
          onClick={() => handleRedirectToKaspi(buyModalItem)}
        >
          <Icon name="kaspipay" />
        </button>
      </div>
    </div>
  </NewModalLayout>
)}

            </li>
          );
        }}
      />
      {isFormModalOpen && (
        <NewModalLayout
          className={`pop-up ${
            isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
          }`}
        >
          <div className="tariff__title-button">
            <h3 className="">{t("FORM.FILL")}</h3>
            <Icon
              name="close-rounded"
              className="lms-badge-profile__close"
              handleClick={() => setIsFormModalOpen(false)}
            />
          </div>
          <form className="form" onSubmit={handleFormSubmit}>
            <Input
              label={t("FORM.NAME")}
              placeholder={t("FORM.FULL_NAME")}
              name="first_name"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  first_name: e.target.value,
                }));
              }}
            />
            <Input
              label={t("FORM.NUMBER")}
              placeholder={t("FORM.FULL_NUMBER")}
              name="phone"
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, phone: e.target.value }));
              }}
            />
            <Button
              className="btn--primary btn--pill"
              type="submit"
              text={t("FORM.SEND")}
            />
          </form>
        </NewModalLayout>
      )}
    </div>
  );
}
