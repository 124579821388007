import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  FAVORITES_ROUTE,
  CATALOG_ROUTE,
  APPLICATION_ROUTE,
  RECOMMENDATIONS_ROUTE,
  ESSAY_ROUTE,
  RESOURCES_ROUTE,
  SCHOLARSHIPS_ROUTE,
} from "@utils/consts";
import { Icon, List } from "@components/common";
import "./DashboardMobile.scss";
import { useTranslate } from "@helpers/hooks";
import { isNewFeature } from "@utils/functions";

export default function DashboardMobile() {
  const { t } = useTranslate();
  const { totalCountFavorites } = useSelector((state) => state.program);
  const links = useMemo(
    () => [
      {
        id: 5,
        route: RECOMMENDATIONS_ROUTE,
        icon: "heart",
        name: t("RECOMMENDATIONS.TITLE"),
      },
      {
        id: 6,
        route: CATALOG_ROUTE,
        icon: "university-program",
        name: t("CATALOG.TITLE"),
      },
      {
        id: 8,
        route: APPLICATION_ROUTE,
        icon: "house",
        name: t("APPLICATION.TITLE"),
        isDivider: true,
      },
      {
        id: 15,
        route: SCHOLARSHIPS_ROUTE,
        icon: "scholarships",
        name: t("SCHOLARSHIPS.TITLE"),
        isNew: isNewFeature("2023-12-15"),
      },
      {
        id: 7,
        route: FAVORITES_ROUTE,
        icon: "saved",
        name:
          totalCountFavorites > 0
            ? `${t("FAVORITE.TITLE")} (${totalCountFavorites})`
            : t("FAVORITE.TITLE"),
      },

      {
        id: 10,
        route: ESSAY_ROUTE,
        icon: "writing-builder",
        name: t("ESSAY.TITLE"),
      },
      {
        id: 13,
        route: RESOURCES_ROUTE,
        icon: "useful",
        name: t("HEADER.USEFUL_RESOURCES"),
      },
    ],
    [t, totalCountFavorites]
  );

  return (
    <div className="dashboard__section-one">
      <List
        items={links}
        className={"dashboard__items"}
        renderItem={(item) => (
          <li key={item.id} className="dashboard__item">
            <NavLink to={item.route}>
              <Icon name={item.icon} className="dashboard__item-icon" />
              <p className="dashboard__item-label">{item.name}</p>
            </NavLink>
          </li>
        )}
      />
    </div>
  );
}
