import { axiosRequest } from "@api/xhr";

export default class MockService {
  static async fetchMockExams() {
    return await axiosRequest.get("lms/mockexams/");
  }

  static async fetchMockExam(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/`);
};

  static async fetchCrit(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/crit/`);
};

  static async fetchMath(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/math/`);
};

static async submitMath(id, data) {
  return await axiosRequest.post(`lms/nuet/${id}/math/save/`, data);
};

static async submitCrit(id, data) {
  return await axiosRequest.post(`lms/nuet/${id}/crit/save/`, data);
};

static async getProgress(id) {
  return await axiosRequest.get(`lms/nuet/${id}/progress/`);
};


static async fetchVerbalOne(id) {
  return await axiosRequest.get(`lms/mockexams/${id}/verbal1/`);
};

static async fetchVerbalTwo(id) {
  return await axiosRequest.get(`lms/mockexams/${id}/verbal2/`);
};

static async fetchMathOne(id) {
  return await axiosRequest.get(`lms/mockexams/${id}/math1/`);
};

static async fetchMathTwo(id) {
  return await axiosRequest.get(`lms/mockexams/${id}/math2/`);
};

static async getSatProgress(id) {
  return await axiosRequest.get(`lms/sat/${id}/progress/`);
};

static async submitVerbalOne(id, data) {
  return await axiosRequest.post(`lms/sat/${id}/verbal1/save/`, data);
};

static async submitVerbalTwo(id, data) {
  return await axiosRequest.post(`lms/sat/${id}/verbal2/save/`, data);
};

static async submitMathOne(id, data) {
  return await axiosRequest.post(`lms/sat/${id}/math1/save/`, data);
};

static async submitMathTwo(id, data) {
  return await axiosRequest.post(`lms/sat/${id}/math2/save/`, data);
};

static async getIeltsProgress(id) {
  return await axiosRequest.get(`lms/ielts/${id}/progress`);
}

static async submitListening(id, data) {
  return await axiosRequest.post(`lms/mockexams/ielts_mock/${id}/listening/save/`, data);
};

static async submitReading(id, data) {
  return await axiosRequest.post(`lms/mockexams/ielts_mock/${id}/reading/save/`, data);
};

static async submitSpeaking(id, data) {
  return await axiosRequest.post(`lms/mockexams/ielts_mock/${id}/speaking/save/`, data);
};

static async submitWriting(id, data) {
  return await axiosRequest.post(`lms/mockexams/ielts_mock/${id}/writing/save/`, data);
};

static async fetchMockListening(id) {
  return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/listening/`);
};

static async fetchMockReading(id) {
  return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/reading/`);
};

static async fetchMockWriting(id) {
  return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/writing/`);
};

static async fetchMockSpeaking(id) {
  return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/speaking/`);
};
}
