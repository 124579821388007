import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useAction, useTranslate } from "@helpers/hooks";
import { Divider, Icon, List, NewFeature } from "@components/common";
import { getNavigationData, isNewFeature } from "@utils/functions";
import classNames from "classnames";
import "./Sidebar.scss";
import { Toggle } from "../Toggle";
import getAdminNavigationData from "@utils/consts/getAdminNavigationData";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Sidebar() {
  const { t } = useTranslate(),
    { pathname } = useLocation(),
    { totalCountFavorites } = useSelector((state) => state.program),
    { profile } = useSelector((state) => state.profile),
    { fetchFavoritesTotalCount, fetchApplicationsTotalCount } = useAction();

  const history = useHistory();

  const isStaff = profile?.is_staff;
  const [isAdminMode, setIsAdminMode] = useState(
    !!localStorage.getItem("admin_mode")
  );

  useEffect(() => {
    fetchFavoritesTotalCount({ is_shortlisted: true });
    fetchApplicationsTotalCount({ is_applying: true });
  }, []);

  const turnOffAdminMode = () => {
    const navRoutes = getNavigationData(
      t,
      totalCountFavorites,
      isNewFeature,
      isStaff
    );
    localStorage.removeItem("admin_mode");
    setIsAdminMode(false);
    history.push(navRoutes[0].route);
  };

  const turnOnAdminMode = () => {
    const navRoutes = getAdminNavigationData(t);
    localStorage.setItem("admin_mode", true);
    setIsAdminMode(true);
    history.push(navRoutes[0].route);
  };

  return (
    <aside className={"sidebar"}>
      <nav className={"sidebar__nav"}>
        {profile?.is_staff && (
          <div className="sidebar__toggle">
            <div className="sidebar__toggle-text">Режим админа</div>
            <div>
              <Toggle
                onLeftClick={turnOffAdminMode}
                onRightClick={turnOnAdminMode}
                initialToggle={isAdminMode}
              />
            </div>
          </div>
        )}
        <List
          className={"sidebar__list"}
          items={
            !isAdminMode
              ? getNavigationData(t, totalCountFavorites, isNewFeature, isStaff)
              : getAdminNavigationData(
                  t,
                  totalCountFavorites,
                  isNewFeature,
                  isStaff
                )
          }
          renderItem={({ id, route, icon, name, isNew, isDivider }) => {
            const shouldHighlight =
              route.includes("blog") ||
              route.includes("scholarships") ||
              route.includes("mock")
                ? pathname.includes(route)
                : pathname === route;
            return (
              <li key={id} className={"sidebar__item"}>
                <NavLink
                  to={route}
                  className={`sidebar__link ${icon}`}
                  activeClassName={classNames({
                    "sidebar__link--selected": shouldHighlight,
                  })}
                >
                  <Icon
                    className={classNames({ "icon--white": shouldHighlight })}
                    name={icon}
                  />
                  <p
                    className={classNames("sidebar__text", {
                      "sidebar__text--selected": shouldHighlight,
                    })}
                  >
                    {name}
                  </p>
                  {isNew && <NewFeature isSelected={shouldHighlight} />}
                </NavLink>

                {isDivider && <Divider className={"sidebar__divider"} />}
              </li>
            );
          }}
        />
      </nav>
    </aside>
  );
}
