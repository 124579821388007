import {createSlice} from "@reduxjs/toolkit";
import {fetchProfile, updateProfile, updateNewUserStatus, submitProftest, fetchProftestResults} from "@store/actions/profileAction";
import { notify } from "@utils/functions";

const initialState = {
    profile: null,
    isLoading: false,
    error: null,
    proftest: null
};

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchProfile.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchProfile.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.profile = action.payload;
        },
        [fetchProfile.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateProfile.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateProfile.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.profile = {...state.profile, ...action.payload};
            notify("Profile successfully updated", 'success');
        },
        [updateProfile.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateNewUserStatus.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateNewUserStatus.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.profile = {...state.profile, ...action.payload};
        },
        [updateNewUserStatus.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [submitProftest.pending.type]: (state) => {
            state.isLoading = true;
        },
        [submitProftest.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.proftest = action.payload;
        },
        [submitProftest.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [fetchProftestResults.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchProftestResults.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.proftest = action.payload;
        },
        [fetchProftestResults.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export default profileSlice.reducer;