import React from "react";
import { useEffect } from "react";
import {Switch, Redirect, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {privateRoutes} from "@routes";
import {useAction, useIsMobile, useScrollToTop} from "@helpers/hooks";
import {PROFILE_ROUTE, COURSE_ROUTE} from "@utils/consts";
import {Layout} from "@components/layout";
import {LmsLayout} from "@components/lmsLayout";
import {MobileLayout} from "@components/mobileLayout";


export default React.memo(function AppRouter({isBannerActive, onCloseBanner}) {
    const {profile} = useSelector(state => state.profile);
    const isMobile = useIsMobile();
    const { fetchMyEducation } = useAction();

    useScrollToTop();

    useEffect(() => {
        fetchMyEducation();
    }, []);

    return (
          <Switch>
            {
                profile && privateRoutes.map(({path, Component}) => (
                    <Route key={path} path={path} exact>
                        {
                            isMobile
                                ?
                            <MobileLayout  isBannerActive={isBannerActive} onCloseBanner={onCloseBanner}>
                                <Component />
                            </MobileLayout>
                                :
                            path.includes(COURSE_ROUTE)
                                ?
                            <LmsLayout>     
                                <Component />
                            </LmsLayout>
                                :
                            <Layout isBannerActive={isBannerActive} onCloseBanner={onCloseBanner}>
                                <Component/>
                            </Layout>
                        }
                    </Route>
                ))
            }
            <Redirect to={PROFILE_ROUTE}/>
        </Switch>
    );
});