import { FormItem } from "@components/common";
import React from "react";
import {getQuestionInfo} from "@utils/functions"
import "./OneChoiceQuestion.scss";

export default function OneChoiceQuestion({ question, userAnswer, onAnswerChange, disabled, isQuizInProgress }) {
  
  let questionData = getQuestionInfo(question);

  const correctAnswer = questionData?.correct_answer  

  return (
    <FormItem
      key={question.id}
      item={{
        id: question.id,
        placeholder: "",
        field: "radio-quiz",
        value: userAnswer || "",
        options: questionData?.answer_options || [],
        correctAnswer: correctAnswer
      }}
      isQuizInProgress={isQuizInProgress}
      handleChange={(e) => onAnswerChange(e.target.value)}
      disabled={disabled}
    />
  );
}
