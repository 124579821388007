import { useState } from "react";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { Button, ButtonLink, Image } from "@components/common";
import { PopupTariffs } from "@components/feature";
import { COURSE_ROUTE } from "@utils/consts";
import { useAction } from "@helpers/hooks";
import "./CourseCard.scss";
import { trackAmplitude } from "@utils/functions";

export default function CourseCard({ course, setIsPriceLoading }) {
  const { t } = useTranslate();
  const [paymentLink, setPaymentLink] = useState(null);
  const price =
    (course?.package_types.length > 0 &&
      course?.package_types[0].plantype.price_per_recur) ||
    "???";
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const { getPaymentLinks } = useAction();

  const onBuyClick = async () => {
    setIsPriceLoading(true);
    try {
      const paymentLinks = await getPaymentLinks({ courseId: course?.id });
      setPaymentInfo(paymentLinks.payload);
      setIsModalOpen(true);
      localStorage.setItem(
        "course_purchase",
        JSON.stringify({ course_id: course.id, enrolled: course.enrolled })
      );
    } catch (error) {
      console.error("Error fetching payment links:", error);
    } finally {
      setIsPriceLoading(false);
      trackAmplitude("learning_platfrom_learn_click", {
        description: "Пользователь успешно открыл тарифы курса для покупки",
        course_name: course.title,
      });
    }
  };

  const handleLinkClick = () => {
    trackAmplitude("learning_platfrom_get_started_click", {
      description: "Пользователь успешно начал свой курс",
      course_name: course.title,
    });
  };
  const handleMoreClick = () => {
    trackAmplitude("learning_platfrom_more_click", {
      description: "Пользователь успешно открыл карточку курса",
      course_name: course.title,
    });
  };

  return (
    <div className={"card"}>
      <Image className="card__cover" src={course?.image} name={'illustration.png'}/>
      <div className="card__content">
        <h4 className="card__title">{course.title}</h4>

        <div className="card__buttons">
          <ButtonLink
            className="button-teritary card__buttons-width card__button"
            link={`${COURSE_ROUTE.replace(":courseId", course.id)}/info`}
            text={t("LMS.BUTTON.MORE")}
            onClick={handleMoreClick}
          />
          {course.enrolled ? (
            <ButtonLink
              className="button-primary card__buttons-width card__button"
              link={`${COURSE_ROUTE.replace(
                ":courseId",
                course.id
              )}/sections/0/onboarding`}
              text={t("LMS.BUTTON.GET_STARTED")}
              onClick={handleLinkClick}
            />
          ) : (
            <Button
              className="card__button card__button-primary card__buttons-width"
              text="Купить"
              onClick={() => onBuyClick()}
            />
          )}

          {isModalOpen && (
            <PopupTariffs
              handleClose={() => setIsModalOpen(false)}
              paymentInfo={paymentInfo}
              course={course}
            />
          )}
        </div>
      </div>
    </div>
  );
}
