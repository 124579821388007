import * as essaysAction from "./essaysAction";
import * as directoryAction from "./directoryAction";
import * as profileAction from "./profileAction";
import * as programAction from "./programAction";
import * as questionnaireAction from "./questionnaireAction";
import * as universityAction from "./universityAction";
import * as courseAction from "./courseAction";
import * as blogAction from "./blogAction";
import * as scholarshipsAction from "./scholarshipsAction";
import * as mockAction from "./mockAction";
import * as challengeAction from "./challengeAction";
import * as coinAction from "./coinAction";

const actions = {
    ...profileAction,
    ...directoryAction,
    ...universityAction,
    ...programAction,
    ...questionnaireAction,
    ...essaysAction,
    ...courseAction,
    ...blogAction,
    ...scholarshipsAction,
    ...mockAction,
    ...challengeAction,
    ...coinAction
};

export default actions;