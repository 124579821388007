import {useMemo, useState} from "react";
import {useAction, useTranslate, useIsMobile} from "@helpers/hooks";
import {EDUCATION_STATUS} from "@utils/consts";
import {debounce} from "@utils/functions";
import {FormItem, List} from "@components/common";
import "./Education.scss";


export default function Education({profile, myEducation}) {
    const isMobile = useIsMobile()
    const {t} = useTranslate(),
        {updateEducation, updateProfile} = useAction(),
        initialValues = useMemo(() => ({
          study_level: myEducation.study_level,
          study_place: profile.study_place,
          planned_study_level: myEducation.planned_study_level,
          number_of_ap_classes: myEducation.number_of_ap_classes,
          gpa_score: myEducation.gpa_score,
          class_rank: myEducation.class_rank,
          planned_study_year: profile.planned_study_year,
        }), [myEducation, profile]),
        [values, setValues] = useState(initialValues),
        EDUCATION_ITEMS = useMemo(() => [
          {
            id: 1,
            label: "PROFILE.EDUCATION_ITEMS.LABEL_1",
            placeholder: "FIELD.NOT_SELECTED",
            field: "dropdown",
            name: "study_level",
            defaultValue: {
                name: t(`EDUCATIONS_STATUS.${values.study_level.toUpperCase()}`),
                value: values.study_level
            },
            value: values.study_level,
            options: EDUCATION_STATUS,
          },
          {
            id: 3,
            label: "PROFILE.EDUCATION_ITEMS.LABEL_2",
            placeholder: "DASHBOARD.POPUP.LABEL_3",
            field: "input",
            name: "study_place",
            type: "text",
            value: values.study_place,
            isProfileData: true,
          },
        ], [values, t]);

    const handleDebounce = useMemo(() => debounce(async (data) => {
        await updateEducation({id: myEducation.id, data});
        // eslint-disable-next-line
    }), []);

    const handleProfileDebounce = useMemo(() => debounce(async (data) => {
        await updateProfile({id: profile.id, data});
        // eslint-disable-next-line
    }), []);

    const handleChange = async (e, field, name) => {
      if (name === "study_place") {
        setValues(prev => ({...prev, [name]: e.target.value}));
        await handleProfileDebounce({[name]: e.target.value});
        return;
      }
      if (field === "dropdown") {
        if (name === "planned_study_year") {
            setValues(prev => ({...prev, [name]: e.value}));
            await handleProfileDebounce({[name]: e.value});
            return;
        }
          setValues(prev => ({...prev, [name]: e.value}));
          await handleDebounce({[name]: e.value});
          return;
      }
      let {value, min, max, maxLength} = e.target;
      if (value.length >= 2 && value[0] === "0" && value[1] === "0") {
          value = value.substring(1);
      }

      if (maxLength !== -1) {
          value = value.slice(0, maxLength);
      }

      if (value.length === 0) {
          value = null;
      }

      setValues(prev => ({...prev, [name]: value}));
      await handleDebounce({[name]: value});
    }

    return (
        <div className={"education"}>
            <List
                className={isMobile ? "education__list-mobile" : "education__list"}
                items={EDUCATION_ITEMS}
                renderItem={(item) => (
                    <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                )}
            />
        </div>
    );
};