import React, { useState, useEffect } from "react";
import { Icon, ButtonLink, Button } from "@components/common";
import { getNextLessonLink } from "@utils/functions";
import { useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";
import { useIsMobile } from "@helpers/hooks";
import { startCoinAnimation } from "@utils/functions";

export default function LessonCompletion({ courseId, sectionId, lessonId, lesson }) {
  const { t } = useTranslate();
  const { course } = useSelector((state) => state.courses);
  const { changeLessonStatus, fetchCourses, fetchBadge } = useAction();
  const isMobile = useIsMobile();

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (lesson.completed) {
      fetchBadge();
    }
  }, [lesson.completed]);

  const onCompletedClick = (lessonId) => {
    changeLessonStatus(lessonId);
    startCoinAnimation(5000);
  };

  return (
    <div>
      {lesson.completed ? (
        <div className={isMobile ? "lesson__button-group-mob" : "lesson__button-group"}>
          <div className="lesson__button-group__completed">
            <Icon name={"completed-lesson"} className="icon--completed" />
            <p>{t("LMS.BUTTON.COMPLETED")}</p>
          </div>
          <ButtonLink
            link={getNextLessonLink(courseId, sectionId, lessonId, course)}
            text={t("LMS.BUTTON.GO_NEXT_ITEM")}
            className={isMobile ? "lesson__button-mob" : "lesson__button"}
          />
        </div>
      ) : (
        <Button
          onClick={() => onCompletedClick(lesson.id)}
          text={t("LMS.BUTTON.MARK_COMPLETED")}
          className={isMobile ? "lesson__button-mob" : "lesson__button"}
        />
      )}
    </div>
  );
}
