import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  ModalSheet,
  List,
  FormItem,
  Image,
  BtnLoader,
} from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import ReactMarkdown from "react-markdown";
import { AudioPlayer } from "@components/feature/AudioPlayer";
import "./AdminMockIeltsTable.scss";
import { UniversityDetailsTabs } from "@components/feature";
import { useSelector } from "react-redux";

export default function AdminMockIeltsTable({
  mockIeltsGrades,
  selectedFilters,
  fetchIeltsMockResponse,
  updateIeltsMockGrade,
  isLoading
}) {
  const { t } = useTranslate();
  const { fetchIeltsMockGrades } = useAction();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState("");
  const { mockResponse } = useSelector((state) => state.mocks);

  const TABS = {
    WRITING: "WRITING",
    SPEAKING: "SPEAKING",
  };

  const [selectedTab, setSelectedTab] = useState(TABS.WRITING);
  const [writingScore, setWritingScore] = useState("");
  const [speakingScore, setSpeakingScore] = useState("");
  const [writingFeedback, setWritingFeedback] = useState("");
  const [speakingFeedback, setSpeakingFeedback] = useState("");

  const openModal = (userId, username) => {
    setSelectedUser(userId);
    setSelectedUsername(username);
    setIsModalOpen(true);
    setWritingScore("");
    setSpeakingScore("");
    setWritingFeedback("");
    setSpeakingFeedback("");
    const mockId = selectedFilters?.mock?.value;
    fetchIeltsMockResponse({ userId, mockId });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    const mockId = selectedFilters?.mock?.value;
    fetchIeltsMockGrades(mockId);
  };

  useEffect(() => {
    if (mockResponse?.writing_responses[0]) {
      setWritingScore(mockResponse.writing_responses[0].writing_score);
      setWritingFeedback(mockResponse.feedback_writing);
    }
    if (mockResponse?.speaking_responses[0]) {
      setSpeakingScore(mockResponse.speaking_responses[0].speaking_score);
      setSpeakingFeedback(mockResponse.feedback_speaking);
    }
  }, [mockResponse]);

  const getContent = (tab) => {
    switch (tab) {
      case TABS.WRITING:
        return (
          <>
            <List
              className="mock-admin__section"
              items={mockResponse.writing_responses}
              renderItem={(item, index) => (
                <li key={item.id}>
                  <h3>{`Part ${index + 1}`}</h3>
                  {item.writing_images[0] && (
                    <Image
                      className="mock-admin__image"
                      src={item.writing_images[0]}
                    />
                  )}
                  <div className="mock-admin__question">
                    <ReactMarkdown>{item.writing_question_text}</ReactMarkdown>
                  </div>
                  <div className="mock-admin__answer-box">
                    <h5>Student's answer: </h5>
                    <p className="mock-admin__answer">{item.answer}</p>
                  </div>
                </li>
              )}
            />{" "}
            <div className="mock-admin__form">
              <FormItem
                item={{
                  id: 1,
                  label: t("ADMIN.GRADE_LABEL_WRITING"),
                  inputClassName: "mock-admin__input",
                  labelClassName: "mock-admin__label",
                  field: "input",
                  name: "writing-score",
                  type: "text",
                  value: writingScore,
                }}
                handleChange={(e) => setWritingScore(e.target.value)}
              />
              <FormItem
                item={{
                  id: 2,
                  label: t("ADMIN.FEEDBACK"),
                  inputClassName: "mock-admin__textarea",
                  labelClassName: "mock-admin__label",
                  field: "input",
                  name: "writing-feedback",
                  type: "text",
                  value: writingFeedback,
                  isTextArea: true,
                }}
                handleChange={(e) => setWritingFeedback(e.target.value)}
              />
              <Button
                className="btn--primary btn--pill"
                text={
                  mockResponse.writing_responses[0]
                    ? "Update grade and feedback"
                    : "Save"
                }
                onClick={handleSubmission}
              />
            </div>
          </>
        );
      case TABS.SPEAKING:
        return (
          <>
            <List
              className="mock-admin__section"
              items={mockResponse.speaking_responses}
              renderItem={(item) => (
                <li key={item.id}>
                  <div className="mock-admin__question">
                    <ReactMarkdown>{item.speaking_question_text}</ReactMarkdown>
                  </div>
                  <AudioPlayer src={item.audio_answer} />
                </li>
              )}
            />
            <div className="mock-admin__form">
              <FormItem
                item={{
                  id: 1,
                  label: t("ADMIN.GRADE_LABEL_SPEAKING"),
                  inputClassName: "mock-admin__input",
                  labelClassName: "mock-admin__label",
                  field: "input",
                  name: "speaking-score",
                  type: "text",
                  value: speakingScore,
                }}
                handleChange={(e) => setSpeakingScore(e.target.value)}
              />
              <FormItem
                item={{
                  id: 2,
                  label: t("ADMIN.FEEDBACK"),
                  inputClassName: "mock-admin__textarea",
                  labelClassName: "mock-admin__label",
                  field: "input",
                  name: "speaking-feedback",
                  type: "text",
                  value: speakingFeedback,
                  isTextArea: true,
                }}
                handleChange={(e) => setSpeakingFeedback(e.target.value)}
              />
              <Button
                className="btn--primary btn--pill"
                text={
                  mockResponse.speaking_responses[0]
                    ? "Update grade and feedback"
                    : "Save"
                }
                onClick={handleSubmission}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleSubmission = () => {
    const numericGrade = Number(
      selectedTab === TABS.WRITING ? writingScore : speakingScore
    );
    const feedback =
      selectedTab === TABS.WRITING ? writingFeedback : speakingFeedback;

    if (isNaN(numericGrade)) {
      console.error("Invalid grade value, must be a number.");
      return;
    }

    const data = {
      [selectedTab === TABS.WRITING ? "writing_score" : "speaking_score"]:
        numericGrade,
      [selectedTab === TABS.WRITING ? "feedback_writing" : "feedback_speaking"]:
        feedback,
    };

    const requestData = {
      userId: selectedUser,
      mockId: selectedFilters?.mock?.value,
      data: data,
    };

    updateIeltsMockGrade(requestData);
  };

  return (
    <div className="mock-admin">
      {mockIeltsGrades?.length > 0 ? (
        <div className="mock-table">
          <div className="mock-table__header">
            <div className=" mock-table__cell--student">Student</div>
            <div className="mock-table__cell">Reading</div>
            <div className="mock-table__cell">Listening</div>
            <div className="mock-table__cell">Speaking</div>
            <div className="mock-table__cell">Writing</div>
            <div className="mock-table__cell">Total</div>
            <div className="mock-table__cell--action"></div>
          </div>
          {mockIeltsGrades.map((grade) => (
            <div key={grade.id} className="mock-table__row">
              <div className=" mock-table__cell--student">
                <img
                  src={grade.user_image}
                  alt={grade.user_name}
                  className="mock-table__user-image"
                />
                <span>{grade.user_name}</span>
              </div>
              <div className="mock-table__cell">{grade.reading_score}</div>
              <div className="mock-table__cell">{grade.listening_score}</div>
              <div className="mock-table__cell">{grade.speaking_score}</div>
              <div className="mock-table__cell">{grade.writing_score}</div>
              <div className="mock-table__cell">{grade.total_score}</div>
              <div className="mock-table__cell--action">
                <Button
                  onClick={() => openModal(grade?.user_id, grade?.user_name)}
                  className="btn--border btn--link btn--pill"
                  text="Подробнее"
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        isLoading && <BtnLoader className="btn-loader-bg-large" />
      )}
      {isModalOpen && (
        <ModalSheet>
          <div className="mock-admin__title">
            <h2 className="mock-admin__username">{selectedUsername}</h2>
            <Icon name="close-rounded" handleClick={closeModal} />
          </div>
          <div>
            <UniversityDetailsTabs
              classNameTabs={
                "university-details__tabs university-details__tabs-main"
              }
              classNameTab={"university-details__tab"}
              tabs={Object.values(TABS)}
              currentTab={selectedTab}
              setCurrentTab={setSelectedTab}
            />
            <div className="mock-admin__content">
              {mockResponse && getContent(selectedTab)}
            </div>
          </div>
        </ModalSheet>
      )}
    </div>
  );
}
