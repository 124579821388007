import {axiosRequest} from "@api/xhr";

export default class LmsService {
    static async fetchCourses() {
        return await axiosRequest.get("lms/courses/");
    };

    static async fetchCourse(id) {
        return await axiosRequest.get(`lms/courses/${id}/`);
    };

    static async fetchMyCourses() {
        return await axiosRequest.get("lms/courses/my");
    };

    static async fetchLesson(lesson_id) {
        return await axiosRequest.get(`lms/lessons/${lesson_id}/`);
    };

    static async changeLessonStatus(id) {
        return await axiosRequest.post(`lms/lessons/${id}/completed`);
    };

    static async submitQuizResults(id, data) {
        return await axiosRequest.post(`lms/quizzes/${id}/save/`, data);
    };

    static async deleteQuizResults(id) {
        return await axiosRequest.delete(`lms/quizzes/results/${id}/delete/`);
    };

    static async createNote(data) {
        return await axiosRequest.post(`lms/notes/`, data);
    };

    static async getNote(id) {
        return await axiosRequest.get(`lms/notes/${id}`);
    };

    static async getLessonNotes(id) {
        return await axiosRequest.get(`lms/lessons/${id}/notes/`);
    };

    static async editNote(id, data) {
        return await axiosRequest.put(`lms/notes/${id}/`, data);
      };

    static async deleteNote(id) {
        return await axiosRequest.delete(`lms/notes/${id}/`);
    };

    static async fetchWorkshop(id) {
        return await axiosRequest.get(`lms/workshop/${id}/`);
    }

    static async fetchWorkshops(id) {
        return await axiosRequest.get(`lms/courses/${id}/workshops/`);
    }

    static async postAttendanceQuizCode(workshopId, code) {
        return await axiosRequest.post(`lms/workshop/${workshopId}/attendance/${code}/`);
    }

    static async fetchGrades(courseId) {
        return await axiosRequest.get(`lms/quizzes/grades/${courseId}/`);
    }

    static async fetchMockGrades(courseId) {
        return await axiosRequest.get(`lms/mock/${courseId}/grades/`);
    }

    static async fetchNotes() {
        return await axiosRequest.get(`lms/notes/`)
    }

    static async fetchCourseNotes(courseId) {
        return await axiosRequest.get(`lms/courses/${courseId}/notes/`)
    }

    static async fetchBadge() {
        return await axiosRequest.get(`lms/badge/`)
    }

    static async fetchBadgeCategories() {
        return await axiosRequest.get(`lms/badge/category/`)
    }

    static async fetchBadgeRating() {
        return await axiosRequest.get(`lms/badge/rating/`)
    }

    static async createBadgeCategory(data) {
        return await axiosRequest.post(`lms/badge/create/`, data)
    }

    static async getPaymentLinks(courseId) {
        return await axiosRequest.get(`lms/courses/${courseId}/payment`)
    }


    static async updateAliveStatus(number) {
        return await axiosRequest.post(`lms/update_alive_status/${number}/`)
    }
    
    static async setToZero() {
        return await axiosRequest.post(`lms/set_0/`)
    }

    static async fetchSquidRanking() {
        return await axiosRequest.get(`lms/squidgame/score/`)
    }

    static async kaspiCreate(course_id, package_type) {
        return await axiosRequest.post(`lms/kaspi/create/`, {course_id, package_type})
    }

    static async kaspiMockCreate(mock_id) {
        return await axiosRequest.post(`lms/kaspi/mock/create/`, mock_id)
    }

    static async mocksFormCreate(form_id) {
        return await axiosRequest.post(`lms/amocrm/create/`, form_id)
    }

    static async fetchGradesAdmin(params) {
        return await axiosRequest.get(`lms_admin/quizzes/grades/`, {params})
    }

    static async fetchAdminCourses() {
        return await axiosRequest.get(`lms_admin/courses/`)
    }

    static async fetchAdminSections(courseId) {
        return await axiosRequest.get(`lms_admin/courses/sections/${courseId}`)
    }

    static async fetchAdminLessons(courseId, sectionId) {
        return await axiosRequest.get(`lms_admin/courses/sections/lessons/${courseId}/${sectionId}`)
    }


}
