import React, { useState } from "react";
import { MockTimer } from "@components/feature";
import { Button, NewModalLayout, CustomLink, Icon } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import {
  WHATSAPP_MOCK_NUET,
  WHATSAPP_MOCK_SAT,
  WHATSAPP_MOCK_IELTS,
  WHATSAPP_CHALLENGE,
} from "@utils/consts";
import "./MockHeader.scss";
import { isIE } from "@amcharts/amcharts4/.internal/core/utils/Utils";

export default function MockHeader({
  mock,
  endTime,
  isNuet,
  isChallenge = false,
  handleSubmit,
  isMockCompleted,
  handleRedirect,
  stage,
  userResponses,
  questionsData,
  score = 0,
  isMarkedForLaterEmpty,
  numberOfQuestionsMarked,
  currentReviewSection,
  isIelts,
}) {
  const getContactLink = (mock) => {
    const contactLinks = {
      nuet_mock: WHATSAPP_MOCK_NUET,
      sat_mock: WHATSAPP_MOCK_SAT,
      ielts_mock: WHATSAPP_MOCK_IELTS,
      challenge: WHATSAPP_CHALLENGE,
    };

    return isChallenge
      ? contactLinks["challenge"]
      : contactLinks[mock?.type] || "";
  };

  const contactLink = getContactLink(mock);
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sectionTitles = {
    math: "Math",
    crit: "Critical Thinking",
    module1: "Verbal Module One",
    module2: "Verbal Module Two",
    module3: "Math Module One",
    module4: "Math Module Two",
    reading: "Reading",
  };

  const totalQuestionsExcludingImages = questionsData?.filter(
    (question) => question?.type !== "question_images"
  ).length;
  const isSubmitDisabled = isMockCompleted
    ? false
    : userResponses?.length !== totalQuestionsExcludingImages;

  const handleButtonClick = () => {
    isMockCompleted
      ? handleRedirect()
      : isMarkedForLaterEmpty
      ? handleSubmit()
      : handleModal();
  };

  const scoreMax = isNuet ? 240 : isIelts ? 9 : isChallenge ? 100 : 800;
  let totalScore = isIelts
    ? mock?.ielts_mock_exam?.score
    : isChallenge
    ? score
    : score?.reduce((acc, score) => {
        return score !== undefined && score !== null ? acc + score : acc;
      }, 0);

  const isSATMath =
    currentReviewSection === "module3" || currentReviewSection === "module4";
  const satMaxModuleScore = 800;

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleButtonAnyway = () => {
    handleSubmit();
    handleModal();
  };

  return (
    <header className="mock__header">
      <nav className="mock__header-nav">
        {isMobile && (
          <Icon name={"arrow-back"} handleClick={handleButtonClick} />
        )}
        <h3 className="mock__ongoing-title">{mock && mock.title}</h3>
        {!isMockCompleted && (
          <MockTimer
            endTime={endTime}
            handleSubmit={handleSubmit}
            stage={stage}
          />
        )}

        <div className="mock__indicators">
          {!isMobile &&
            !isNuet &&
            isMockCompleted &&
            !isIelts &&
            !isChallenge &&
            score && (
              <div className="mock__progress">
                {isSATMath
                  ? `${t("MOCK.MATH")} ${score[0]}/${satMaxModuleScore}`
                  : `${t("MOCK.VERBAL")} ${score[1]}/${satMaxModuleScore}`}
              </div>
            )}
          {!isMobile && totalScore !== undefined && (
            <div className="mock__progress">
              {!isMockCompleted
                ? `${userResponses?.length} / ${totalQuestionsExcludingImages}`
                : `${t("MOCK.YOUR_SCORE")} ${totalScore}/${scoreMax}`}
            </div>
          )}
          {isMockCompleted && (
            <CustomLink
              className={"ongoing__button"}
              isExternal={true}
              newTab={true}
              to={contactLink}
            >
              {t("MOCK.CONTACT")}
            </CustomLink>
          )}
          {!isMobile && (
            <div className="mock__buttons">
              <Button
                className="btn--primary mock__btn"
                text={t(
                  isMockCompleted
                    ? "MOCK.FINISH_REVIEW"
                    : stage === "math"
                    ? "MOCK.NEXT_SECTION"
                    : "MOCK.SUBMIT_EXAM"
                )}
                onClick={handleButtonClick}
                disabled={isSubmitDisabled}
              />
            </div>
          )}
        </div>
      </nav>

      {isModalOpen && (
        <NewModalLayout
          className={`pop-up ${
            isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
          }`}
        >
          <div className="tariff__title-button">
            <h3 className="">{t("MOCK.LATER_TITLE")}</h3>
          </div>

          <p>{t("MOCK.LATER_WARNING", { numberOfQuestionsMarked })}</p>

          <div className="popup__buttons">
            <Button
              className="button-secondary popup__button"
              text={t("MOCK.CANCEL")}
              onClick={() => handleModal()}
            />

            <Button
              className="button-primary popup__button"
              text={t("MOCK.SUBMIT_ANYWAY")}
              onClick={() => handleButtonAnyway()}
            />
          </div>
        </NewModalLayout>
      )}
    </header>
  );
}
