import { lazy } from "react";
import {
  DASHBOARD_ROUTE,
  FAVORITES_ROUTE,
  SETTINGS_ROUTE,
  PROFILE_ROUTE,
  RECOMMENDATIONS_ROUTE,
  RESOURCES_ROUTE,
  CATALOG_ROUTE,
  APPLICATION_ROUTE,
  LEARNING_PLATFORM_ROUTE,
  COURSE_ROUTE,
  ESSAY_ROUTE,
  MORE_ROUTE,
  // RECOMMENDATION_LETTTER_ROUTE,
  // UNIVERSITY_VISITS_ROUTE,
  // ASSESMENTS_ROUTE,
  COURSE_GRADES_ROUTE,
  COURSE_NOTES_ROUTE,
  COURSE_RANKING_ROUTE,
  COURSE_INFO_ROUTE,
  COURSE_SECTION_ROUTE,
  COURSE_ONBOARDING_ROUTE,
  COURSE_LESSON_ROUTE,
  COURSE_LESSON_TEST_ROUTE,
  PROFILE_MOBILE,
  LANGUAGE_MOBILE,
  WORKSHOP_ROUTE,
  WORKSHOP_MAIN_ROUTE,
  BLOG_ROUTE,
  BLOG_ARTICLE_ROUTE,
  SCHOLARSHIPS_ROUTE,
  SCHOLARSHIPS_PROFILE_ROUTE,
  MARATHON_ADMIN,
  MOCK_EXAMS,
  MOCK_EXAM,
  MOCK_EXAM_ONGOING,
  KASPI_PAYMENT_ROUTE,
  CHALLENGES_ROUTE,
  CHALLENGES_ROUTE_ONGOING,
  MOCK_EXAMS_ADMIN_ROUTE,
  GRADES_ADMIN_ROUTE,
} from "@utils/consts";

const Dashboard = lazy(() =>
  import("../pages/Dashboard/Dashboard").then((module) => module)
);
const Profile = lazy(() =>
  import("../pages/Profile/Profile").then((module) => module)
);
const Recommendations = lazy(() =>
  import("../pages/Recommendations/Recommendations").then((module) => module)
);
const Catalog = lazy(() =>
  import("../pages/Catalog/Catalog").then((module) => module)
);
const Favorites = lazy(() =>
  import("../pages/Favorites/Favorites").then((module) => module)
);
const Resources = lazy(() =>
  import("../pages/Resources/Resources").then((module) => module)
);
const Settings = lazy(() =>
  import("../pages/Settings/Settings").then((module) => module)
);
const Application = lazy(() =>
  import("../pages/Application/Application").then((module) => module)
);

const Blog = lazy(() => import("../pages/Blog/Blog").then((module) => module));
const BlogArticle = lazy(() =>
  import("../pages/BlogArticle/BlogArticle").then((module) => module)
);
const Courses = lazy(() =>
  import("../pages/Courses/Courses").then((module) => module)
);
const Course = lazy(() =>
  import("../pages/Course/Course").then((module) => module)
);
const CourseInfo = lazy(() =>
  import("../pages/CourseInfo/CourseInfo").then((module) => module)
);
const Lesson = lazy(() =>
  import("../pages/Lesson/Lesson").then((module) => module)
);
const LessonPractice = lazy(() =>
  import("../pages/LessonPracticeTest/LessonPracticeTest").then(
    (module) => module
  )
);
const Essays = lazy(() =>
  import("../pages/Essays/Essays").then((module) => module)
);
const More = lazy(() => import("../pages/More/More").then((module) => module));

const MobileProfile = lazy(() =>
  import("../components/feature/Mobile/MobileProfile/MobileProfile").then(
    (module) => module
  )
);

// const RecommendationLetter = lazy(() =>
//   import("../pages/RecommendationLetter/RecommendationLetter").then((module) => module)
// );
// const UniversityVisits = lazy(() =>
//   import("../pages/UniversityVisits/UniversityVisits").then((module) => module)
// );
// const Assesments = lazy(() =>
//   import("../pages/Assesments/Assesments").then((module) => module)
// );
const Workshop = lazy(() =>
  import("../pages/Workshop/Workshop").then((module) => module)
);

const CourseGrades = lazy(() =>
  import("../pages/CourseGrades/CourseGrades").then((module) => module)
);
const CourseNotes = lazy(() =>
  import("../pages/CourseNotes/CourseNotes").then((module) => module)
);

const CourseRanking = lazy(() =>
  import("../pages/CourseRanking/CourseRanking").then((module) => module)
);

const MaterialSection = lazy(() =>
  import("../pages/MaterialSection/MaterialSection").then((module) => module)
);
const OnboardingSection = lazy(() =>
  import("../pages/OnboardingSection/OnboardingSection").then(
    (module) => module
  )
);
const MockExams = lazy(() =>
  import("../pages/MockExams/MockExams").then((module) => module)
);
const MockOngoing = lazy(() =>
  import("../pages/MockOngoing/MockOngoing").then((module) => module)
);
const Scholarships = lazy(() =>
  import("../pages/Scholarships/Scholarships").then((module) => module)
);
const ScholarshipsProfile = lazy(() =>
  import("../pages/ScholarshipsProfle/ScholarshipsProfle").then(
    (module) => module
  )
);

const MarathonAdmin = lazy(() =>
  import("../pages/MarathonAdmin/MarathonAdmin").then((module) => module)
);

const LanguageMobile = lazy(() =>
  import("../components/feature/Mobile/MobileLanguage/MobileLanguage").then(
    (module) => module
  )
);

const KaspiPayment = lazy(() =>
  import("../pages/KaspiPayment/KaspiPayment").then((module) => module)
);

const Challenges = lazy(() =>
  import("../pages/Challenges/Challenges").then((module) => module)
);

const ChallengeOngoing = lazy(() =>
  import("../pages/ChallengeOngoing/ChallengeOngoing").then((module) => module)
);

const MockAdmin = lazy(() =>
  import("../pages/MockAdmin/MockAdmin").then((module) => module)
);

const GradesAdmin = lazy(() =>
  import("../pages/GradesAdmin/GradesAdmin").then((module) => module)
);



export const privateRoutes = [
  {
    path: DASHBOARD_ROUTE,
    Component: Dashboard,
  },
  {
    path: PROFILE_ROUTE,
    Component: Profile,
  },
  {
    path: RECOMMENDATIONS_ROUTE,
    Component: Recommendations,
  },
  {
    path: CATALOG_ROUTE,
    Component: Catalog,
  },
  {
    path: FAVORITES_ROUTE,
    Component: Favorites,
  },
  {
    path: RESOURCES_ROUTE,
    Component: Resources,
  },
  {
    path: SETTINGS_ROUTE,
    Component: Settings,
  },
  {
    path: APPLICATION_ROUTE,
    Component: Application,
  },
  {
    path: LEARNING_PLATFORM_ROUTE,
    Component: Courses,
  },
  {
    path: COURSE_ROUTE,
    Component: Course,
  },
  {
    path: COURSE_INFO_ROUTE,
    Component: CourseInfo,
  },
  {
    path: COURSE_LESSON_ROUTE,
    Component: Lesson,
  },
  {
    path: COURSE_LESSON_TEST_ROUTE,
    Component: LessonPractice,
  },
  {
    path: ESSAY_ROUTE,
    Component: Essays,
  },
  {
    path: MORE_ROUTE,
    Component: More,
  },
  {
    path: PROFILE_MOBILE,
    Component: MobileProfile,
  },
  {
    path: LANGUAGE_MOBILE,
    Component: LanguageMobile,
  },
  // {
  //   path: RECOMMENDATION_LETTTER_ROUTE,
  //   Component: RecommendationLetter,
  // },
  // {
  //   path: UNIVERSITY_VISITS_ROUTE,
  //   Component: UniversityVisits,
  // },
  // {
  //   path: ASSESMENTS_ROUTE,
  //   Component: Assesments,
  // },
  {
    path: WORKSHOP_ROUTE,
    Component: Workshop,
  },
  {
    path: WORKSHOP_MAIN_ROUTE,
    Component: Workshop,
  },
  {
    path: COURSE_GRADES_ROUTE,
    Component: CourseGrades,
  },
  {
    path: COURSE_NOTES_ROUTE,
    Component: CourseNotes,
  },
  {
    path: COURSE_RANKING_ROUTE,
    Component: CourseRanking,
  },
  {
    path: COURSE_INFO_ROUTE,
    Component: CourseInfo,
  },
  {
    path: COURSE_SECTION_ROUTE,
    Component: MaterialSection,
  },
  {
    path: COURSE_ONBOARDING_ROUTE,
    Component: OnboardingSection,
  },
  {
    path: MOCK_EXAMS,
    Component: MockExams,
  },
  {
    path: MOCK_EXAM_ONGOING,
    Component: MockOngoing,
  },
  {
    path: BLOG_ROUTE,
    Component: Blog,
  },
  {
    path: BLOG_ARTICLE_ROUTE,
    Component: BlogArticle,
  },
  {
    path: SCHOLARSHIPS_ROUTE,
    Component: Scholarships,
  },
  {
    path: SCHOLARSHIPS_PROFILE_ROUTE,
    Component: ScholarshipsProfile,
  },
  {
    path: MARATHON_ADMIN,
    Component: MarathonAdmin,
  },
  {
    path: KASPI_PAYMENT_ROUTE,
    Component: KaspiPayment,
  },
  {
    path: CHALLENGES_ROUTE,
    Component: Challenges,
  },
  {
    path: CHALLENGES_ROUTE_ONGOING,
    Component: ChallengeOngoing,
  },
  {
    path: MOCK_EXAMS_ADMIN_ROUTE,
    Component: MockAdmin,
  },
  {
    path: GRADES_ADMIN_ROUTE,
    Component: GradesAdmin,
  },


];
