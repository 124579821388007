import React, { useEffect, useState, useRef } from "react";
import { formatTime } from "@utils/functions";
import "./MockTimer.scss";

export default function MockTimer({ endTime, handleSubmit, stage }) {
  const [timeLeft, setTimeLeft] = useState(null);
  const hasSubmittedRef = useRef(false);
  const warningTime = 60000

  useEffect(() => {
    if (endTime && stage !== "finished") {
      const end = new Date(endTime).getTime();
      let timer;

      const updateRemainingTime = () => {
        const now = new Date().getTime();
        const distance = end - now;
        if (distance <= 0 && !hasSubmittedRef.current) {
          clearInterval(timer);
          handleSubmit();
          hasSubmittedRef.current = true;
        } else {
          setTimeLeft(Math.max(distance, 0));
        }
      };

      updateRemainingTime();
      timer = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timer);
    }
  }, [endTime, handleSubmit, stage]); 

  return (
    <div>
      <p
        className={`timer__numbers ${
          stage === "finished" ? "timer__blue" : timeLeft <= warningTime ? "timer__red" : "timer__green"
        }`}
      >
        {stage === "finished" ? "Review" : formatTime(timeLeft)}
      </p>
    </div>
  );
}
