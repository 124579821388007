import { MOCK_EXAMS_ADMIN_ROUTE, GRADES_ADMIN_ROUTE } from "@utils/consts";

const getAdminNavigationData = (t) => [
  {
    id: 18,
    route: MOCK_EXAMS_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.MOCK_EXAMS"),
  },
  {
    id: 19,
    route: GRADES_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.GRADES"),
  },
];

export default getAdminNavigationData;
