function getQuestionInfo(question) {
  let questionData;
  const questionType = question?.type;

  if (questionType?.includes("mock")) {
    questionData = question[question[question.type]];
  } else {
    questionData = question["question_" + questionType];
  }

  return questionData;
}

export default getQuestionInfo;
