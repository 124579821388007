import { useMemo } from "react";
import { ActionsMenu, Image } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import { formatData } from "@utils/functions";
import USAIcon from "../../../../assets/US.png";
import GBIcon from "../../../../assets/GB.png";
import "./EssaysGeneralListItem.scss";

const EssaysGeneralListItem = ({item, onSelectEssay, onEditEssay}) => {
    const { t } = useTranslate(),
    { deleteGeneralEssay, fetchGeneralEssay, fetchAvailableTypes } = useAction(),
    ITEMS_INFO = useMemo(() => [
        {
            id: 1,
            key: t("ESSAY.LABELS.LABEL_4"),
            value: item.topic ?? "???",
        },
        {
            id: 2,
            key: t("ESSAY.LABELS.LABEL_5"),
            value: (item.updated_at && formatData(item.updated_at, 3)) ?? "???",
        },
        {
            id: 3,
            key: t("ESSAY.LABELS.LABEL_6"),
            value: item.word_limit ?? "???",
        },
    ], [t, item]),
    typeToElements = {
        us: {
          image: <Image src={USAIcon} />,
          label: <p>{t("ESSAY.LABELS.US")}</p>,
        },
        uk: {
          image: <Image src={GBIcon} />,
          label: <p>{t("ESSAY.LABELS.UK")}</p>,
        },
    };
  
    
    const handleOpenModal = () => {
        onSelectEssay(item.id);
        fetchGeneralEssay(item.id);
        fetchAvailableTypes();
    }

    const handleEdit = () => {
        onEditEssay(item.id);
    }
    
    const handleDelete = () => {
        deleteGeneralEssay(item.id)
    }

    const ACTIONS = useMemo(() => [
        {id: 1, name: t("ESSAY.ACTION.CHECK_THE_ESSAY"), click: handleOpenModal, icon: 'question'},
        {id: 2, name: t("ESSAY.ACTION.EDIT"), click: handleEdit, icon: 'edit'},
        {id: 3, name: t("ESSAY.ACTION.DELETE"), click: handleDelete, icon: 'delete-trash'},
    ], [t]);
    
    return (
        <div className="essays-list-item">
            <div className="essays-list-item__content">
                <div className="essays-list-item__pair">
                    <div className="essays-list-item__key">{t("ESSAY.LABELS.LABEL_3")}</div>
                    <div className="essays-list-item__type">
                        {typeToElements[item.type]?.image}
                        {typeToElements[item.type]?.label}
                    </div>
                </div>
                {
                    ITEMS_INFO.map(el => (
                        <div key={el.id} className="essays-list-item__pair">
                            <h1 className="essays-list-item__key">{el.key}</h1>
                            <p className="essays-list-item__value">{el.value}</p>
                        </div>
                    ))
                }
            </div>
            <ActionsMenu actions={ACTIONS}/>
        </div>
    );
}

export default EssaysGeneralListItem;
